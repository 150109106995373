import { PaginationState } from "@tanstack/react-table";
import { FaExclamationCircle } from "react-icons/fa";
import { format, isValid, parseISO } from "date-fns";
import { Field, Form, Formik, FormikErrors } from "formik";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { setBlocker, clearBlocker } from '../../shared/slice/blockerSlice'
import {
  GetReferenceService,
  addProducts,
  createOrder,
  deleteProducts,
  downloadOrderFile,
  editOrder,
  editProducts,
  getNtinCodes,
  getOrderProducts,
  searchOrderById,
} from "../../common/api/ordersApi";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../shared/components/Table/Pagination";
import style from "./NewOrder.module.css";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import {
  getOrderXMLForSigning,
  saveSignedOrder,
} from "../../common/api/orderSignature";
import AlertBox from "../../shared/Ui/AlertBox/AlertBox";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import Loader from "../../shared/Ui/Loader/Loader";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import { useAppDispatch, useAppSelector } from "../../shared/hook";
import { useTranslation } from "react-i18next";
import PageNotRole from "../../shared/Ui/PageNotFound/PageNotRole";

function NewOrder() {
  const roleMatrix = useAppSelector(state => state.roleMatrix)
  const [role, setRole] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [releaseMethod, setReleaseMethod] = useState([]);
  const [isKazakhstanSelected, setIsKazakhstanSelected] = useState<any>(false);
  const [countryOrigin, setCountryOrigin] = useState([]);
  const { orderId } = useParams();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [stackDelete, setStackDelete] = useState<any>([]);
  const [stackEdit, setStackEdit] = useState<any>([]);
  const [stackAddCount, setStackAddCount] = useState<any>(0);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const saveButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [statusType, setStatusType] = useState({
    type: "unsaved",
    label: t("documentStatus.new"),
  });
  const [productTypeCode, setProductTypeCode] = useState([]);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [dowloadModal, setDowloadModal] = useState<any>(null);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  interface ITableDataItem {
    orderId: number;
    productTypeCode: string;
    amount: string;
    weight: string;
    size: string;
  }

  interface IInitialValues {
    id: string;
    org_id: string;
    create_user_id: string;
    createdAt: any;
    updatedAt: any;
    signingAt: any;
    status: string;
    refReleaseMethod: string | undefined;
    refCountryOrigin: any;
    note: any;
    error_reason: string;
    number: string;
    stamp:string;
    orderProducts: ITableDataItem[];
  }
  const [initialValues, setInitialValues] = useState<IInitialValues>({
    id: "",
    org_id: "generatedOrgId", // ID организации
    create_user_id: "generatedUserId", // ID создавшего пользователя
    createdAt: "", // Дата и время создания
    updatedAt: "timestamp", // Дата и время последнего изменения
    signingAt: "timestamp", // Дата и время подписания
    status: "new", // Статус, например "Обработан", "Ошибочный" и т.д.
    refReleaseMethod: undefined, // Способ выпуска в оборот, ID метода
    refCountryOrigin: undefined, // Страна производства, ID страны
    note: null, // Примечание
    error_reason: "", // Причина ошибки
    number: "", // Регистрационный номер
    stamp:"",
    orderProducts: [],
  });
  const formValidationSchema = Yup.object().shape({
    refReleaseMethod: Yup.string().required(t("documentsError.required")),
    refCountryOrigin: Yup.string()
      .required(t("documentsError.required"))
      .when("refReleaseMethod", (refReleaseMethod) => {
        if (refReleaseMethod[0] === "2") {
          return (
            refReleaseMethod[0] &&
            Yup.string().notOneOf(["130"], t("documentsError.common_error"))
          );
        }
      }),
    note: Yup.string().nullable()
    .min(3, t("documentsError.minCharacters", { count: 3 }))
    .max(256,t("documentsError.maxCharacters", { count: 256 }),),
    stamp:Yup.string()
    .required(t("documentsError.required"))
    .min(1, t("documentsError.minCharacters", { count: 1 }))
    .max(4, t("documentsError.maxCharacters", { count: 4 })),
    orderProducts: Yup.array().of(
      Yup.object().shape({
        productTypeCode: Yup.string()
          .matches(
            /^\d{14}$/,
            t("documentsError.number_of_characters", { count: 14 }),
          )
          .required(t("documentsError.required")),
        amount: Yup.number()
          .required(t("documentsError.required"))
          .min(1, t("documentsError.minCharacters", { count: 1 })),
      }),
    ),
  });


  function onLoad() {
    if (!orderId) return;
    // Запрос на получение основной информации
    setLoading(true); // Устанавливаем loading в true перед началом загрузки
    searchOrderById(orderId)
      .then((orderResponse) => {
        console.log("Загрузка информации о заказе:", orderResponse);
        return getOrderProducts(orderId).then((productsResponse) => {
          console.log("Загрузка списка изделий заказа:", productsResponse);

          return {
            orderResponse: orderResponse?.data ? orderResponse.data : {},
            productsResponse: productsResponse.orderProducts
              ? productsResponse.orderProducts
              : [],
          };
        });
      })
      .then(({ orderResponse, productsResponse }) => {
        orderResponse.refReleaseMethod == 1 ? setIsKazakhstanSelected(130) : {};
        const dataWithOrderProducts = {
          ...orderResponse,
          refCountryOrigin:
            orderResponse.refReleaseMethod == 1
              ? 130
              : orderResponse.refCountryOrigin,
          orderProducts: productsResponse,
        };
        setInitialValues(dataWithOrderProducts);
        setLoading(false); // Устанавливаем loading в false после завершения загрузки
      })
      .catch((error) => {
        console.error("Ошибка при загрузке данных:", error);
        navigate("/error", {
          state: { error: error?.response?.data?.message?.toString() },
        });
        setLoading(false); // Устанавливаем loading в false в случае ошибки
      });
  }


  useEffect(() => {
    const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 2) }

    setRole(roleObj)

    if (orderId !== undefined) {
      onLoad();
    } else {
      setLoading(false);
    }
    GetReferenceService("app/release-methods/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setReleaseMethod(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });

    GetReferenceService("app/countries/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setCountryOrigin(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
    getNtinCodes().then((response: any) => {
      const options = response.map((item: any) => ({
        label: `${item.name}: ${item.productTypeCode}`,
        value: item.productTypeCode
      }));
      setProductTypeCode(options);
    });
  }, [orderId]);

  const roleAcces = (actionId: string) => {
    return roleMatrix?.roleMatrix
      ?.find((item: any) => item.id === 2)
      ?.specificAction?.actions
      ?.some((action: any) => action.id === actionId && action.active) || false;
  }

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  const addNewBlock = (formik: any) => {
    const orderProducts: any = {
      orderId: nanoid(),
      productTypeCode: "",
      amount: "1",
      weight: "",
      size: "",
    };

    if (orderId) {
      setStackAddCount((prevStack: any) => prevStack + 1);
    }

    const newTable = [orderProducts, ...formik.values.orderProducts];
    formik.setFieldValue("orderProducts", newTable);
    formik.resetForm({
      values: {
        ...formik.values,
        orderProducts: newTable,
      },
    });
  };

  const removeBlock = (
    atributId: number,
    id: number,
    index: number,
    formik: any,
  ) => {
    const newTable = formik.values.orderProducts.filter(
      (_: any, i: any) => i !== index,
    );

    if (orderId) {
      setStackDelete((prevStack: any) => [...prevStack, id]);
    }

    if (orderId && !id) {
      setStackAddCount((prevStack: any) => prevStack - 1);
    }

    formik.setFieldValue("orderProducts", newTable);

    // Обновяет ошибки формы

    // formik.resetForm({
    //   values: {
    //     ...formik.values,
    //     orderProducts: newTable,
    //   },
    // });
  };

  const checkNtinLength = (orderProducts: any) => {
    return orderProducts.every((product: any) => product.ntin?.length === 14);
  };

  const handleProductChange = (
    index: any,
    field: any,
    value: any,
    formik: any,
  ) => {
    if (formik.values.orderProducts[index].id) {
      setStackEdit((prev: any) => {
        const updatedProducts = [...prev];
        const productIndex = updatedProducts.findIndex(
          (product) => product.id === formik.values.orderProducts[index].id,
        );
        if (productIndex >= 0) {
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            [field]: value,
          };
        } else {
          updatedProducts.push({
            ...formik.values.orderProducts[index],
            [field]: value,
          });
        }
        
        
        return updatedProducts;
      });
    }
  };

  function getTableDataErrorMessage(
    errors: FormikErrors<{ orderProducts: ITableDataItem[] }>,
    index: number,
    field: keyof ITableDataItem,
  ): string | undefined {
    if (Array.isArray(errors.orderProducts) && errors.orderProducts[index]) {
      const itemError = errors.orderProducts[
        index
      ] as FormikErrors<ITableDataItem>;
      return itemError[field] as string | undefined;
    }
    return undefined;
  }

  // Это статус ВНИМАНИЕ!
  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("orderList2.draft") };
      case "ERROR":
        return { type: "error", label: t("orderList2.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("orderList2.in_processing") };
      case "READY_TO_RECEIVE":
        return { type: "active", label: t("orderList2.ready_to_receive") };
      case "PROCESSED_NO_FUNDS":
        return { type: "test", label: t("orderList2.processed_no_funds") };
      case "PROCESSED_PAID":
        return { type: "active", label: t("orderList2.processed_paid") };
      default:
        return { type: "new", label: t("orderList2.unsaved") }; // Для пустого статуса или неизвестного статуса
    }
  }


  // Это главный запрос ВНИМАНИЕ!
  async function submitCreate(formikValues: any) {
    dispatch(clearBlocker())
    setIsFormChanged(false);
    const backendData: any = {
      refReleaseMethod: formikValues.refReleaseMethod,
      refCountryOrigin:
        formikValues.refReleaseMethod === 1 ? null : formikValues.refCountryOrigin,
      note: formikValues.note || null,
      stamp: formikValues.stamp,
    };
  
    // Добавляем поле orderProducts только если orderId не определен
    if (orderId === undefined) {
      backendData.orderProducts = formikValues?.orderProducts;
    }

    if (orderId !== undefined) {
      if (stackEdit?.length > 0) {
        await editProducts(orderId, stackEdit)
          .then((data) => setStackEdit([]))
          .catch((error) => {
            console.error("Error editing products:", error);
          });
      }

      if (stackDelete?.length > 0) {
      await deleteProducts(orderId, stackDelete)
        .then((data) =>  setStackDelete([]))
        .catch((error) => {
          setAlertOpen({
            status: error,
            message: t("documentsError.order_delete_error"),
          });
        });}

      const newProducts = formikValues?.orderProducts.slice(0, stackAddCount);
      if (newProducts?.length > 0) {
        await addProducts(orderId, newProducts)
          .then(() => setStackAddCount([]))
          .catch((error) => {
            console.error("Error adding new products:", error);
            setAlertOpen({
              status: error,
              message: t("documentsError.order_add_error"),
            });
          });
      }

      await editOrder(orderId, backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          onLoad();
          setAlertOpen({
            status: response.status,
            message: response.message
              ? response.message
              : t("new_order.save_success_message"),
          });
        })
        .catch((error) => {
          setAlertOpen({
            status: error,
            message: t("new_order.file_error_message"),
          });
        });
    } else {
      await createOrder(backendData)
        .then((response) => {
          setStackDelete([])
          setStackEdit([])
          setStackAddCount([])
          navigate(`/orders/${response.data.id}`)
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: error,
            message: t("new_order.create_error_message"),
          });
        });
    }
  }

  // Это ЗАПРОС НА ПОДДПМСАНИЕ ЗАЯВКИ!
  async function submitXmlProduct() {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: t("documentNotification.nca_no_connect"),
      });
      return;
    }

    let xml;
    try {
      setIsLoading(true);
      xml = await getOrderXMLForSigning(orderId);
      console.log(xml.data);
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      const errorMessage =
        e.response?.data?.message || t("new_order.get_xml_error");
      setErrorModal({ errorMessage });
      return;
    } finally {
      setIsLoading(false);
    }

    let xmlSignature;
    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml.data,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      const errorMessage =
        e.response?.data?.message || t("new_order.sign_xml_error");
      setErrorModal({ errorMessage });
      return;
    }

    try {
      setIsLoading(true);
      const orderIdValue = orderId ?? "0";
      await saveSignedOrder(orderIdValue, xmlSignature[0]);
      alert(t("new_order.edit_success_message"));
      navigate("/orders");
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      const errorMessage =
        e.response?.data?.message || t("new_order.save_error_message");
      setErrorModal({ errorMessage });
      onLoad();
    } finally {
      setIsLoading(false);
    }
  }

  // Это ЗАПРОС НА ФАЙЫ ЗАЯВКИ!
  const handleDownloadClick = async (orderId: any, fileType: any) => {
    try {
      setIsLoading(true);
      const fileBlob = await downloadOrderFile(orderId, fileType);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `order-${orderId}.${fileType}`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Это БОКИРУЕМ ПОКИДАНИЕ СТРАНИЦЫ ЕСЛИ ЕСТЬ ИЗМЕНЕНИЯ!
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Проверяем состояние кнопки "Сохранить"
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена

      currentLocation.pathname !== nextLocation.pathname,
  );
  const daysDifference = (signingAt: string) => {
    // Проверяем, что значение `signingAt` является строкой
    if (typeof signingAt !== "string") {
      console.error("Неверный формат даты:", signingAt);
      return null; // Или любое другое значение по умолчанию
    }
  
    const [datePart, timePart] = signingAt.split(" ");
    // Дополнительная проверка, чтобы убедиться, что разбиение прошло успешно
    if (!datePart || !timePart) {
      console.error("Неверный формат даты:", signingAt);
      return null;
    }
  
    const [day, month, year] = datePart.split(".");
    const [hours, minutes, seconds] = timePart.split(":");
  
    if (!day || !month || !year || !hours || !minutes || !seconds) {
      console.error("Неверный формат даты:", signingAt);
      return null;
    }
  
    const pastDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes),
      parseInt(seconds)
    );
  
    const todayDate = new Date();
    const differenceInTime = todayDate.getTime() - pastDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  
    return differenceInDays;
  };
  

  if (loading && !role) return <Loader/>;

  if (!role || !role?.view) {
    return (
      <PageNotRole />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues) => submitCreate(formikValues)}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={formValidationSchema}
    >
      {(formik) => {
        const totalProductsCount = formik.values.orderProducts?.length;
        const totalAmount = formik.values.orderProducts.reduce(
          (acc, current) => acc + Number(current.amount || 0),
          0,
        );
        return (
          <Form className="max-w-[1585px] w-full m-t-[20px]">
          {isBlocked && blocker.state === "blocked" ? (
             <ModalComponent
             isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
             onClose={() => {
               if (blocker && typeof blocker.reset === "function") {
                 blocker.reset();
               } else {
                 console.error("Reset function is not available.");
               }
             }}
             headerContent={
               <>
                 <span>{t("documentNotification.notification")}</span>
                 <button
                   type="button"
                   onClick={() => {
                     if (blocker && typeof blocker.reset === "function") {
                       blocker.reset();
                     } else {
                       console.error("Reset function is not available.");
                     }
                   }}
                   aria-label="Close"
                   className="float-right cursor-pointer bg-none border-none"
                 >
                   &times; {/* Символ крестика */}
                 </button>
               </>
             }
             content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
             customContent={
               <>
                <ModalButton
       type="button"
       onClick={() => {
        dispatch(clearBlocker())
         if (blocker && typeof blocker.proceed === "function") {
           blocker.proceed(); // Уходим со страницы без сохранения
         } else {
           console.error("Proceed function is not available.");
         }
       }}
     >
       {t("mainButtons.cancel")}
     </ModalButton>
                 <ModalButton
                   type="button"
                   color="green"
                   onClick={async () => {
                    dispatch(clearBlocker())
                     try {
                       await submitCreate(formik.values); // Сохранение данных
                       if (blocker && typeof blocker.proceed === "function") {
                         blocker.proceed(); // Переход после сохранения
                       }
                     } catch (error) {
                       console.error("Error saving data:", error);
                       // Можно обработать ошибку, например, показать уведомление пользователю
                     }
                   }}
                 >
                   {t("mainButtons.save")}
                 </ModalButton>
               </>
             }
           />
            ) : null}
            <ModalComponent
              isOpen={errorModal}
              onClose={() => {
                setErrorModal(null);
              }}
              headerContent={t("documentNotification.notification")}
              content={
                <p>
                  {" "}
                  {`${t("documents_file_upload.errors_list")}: ${errorModal?.errorMessage ? errorModal?.errorMessage : "ошибка."}`}
                </p>
              }
              customContent={
                <ModalButton
                  type="button"
                  onClick={() => {
                    setErrorModal(null);
                  }}
                >
                  {t("mainButtons.accept")}
                </ModalButton>
              }
            />
            {/* ТУТ СКАЧИВАЮТСЯ ДОКУМЕНТЫ */}

            <ModalComponent
              isOpen={dowloadModal}
              onClose={() => {
                setDowloadModal(null);
              }}
              headerContent={t("orderList2.downloadFile")}
              content={
                <div className="flex flex-col space-y-2">
                  <button
                    onClick={() => handleDownloadClick(orderId, "PDF")}
                    className="bg-red-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    type="button"
                  >
                    {t("orderList.download")} PDF
                  </button>
                  <button
                    onClick={() => handleDownloadClick(orderId, "CSV")}
                    className="bg-green text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    type="button"
                  >
                    {t("orderList.download")} CSV
                  </button>
                  <button
                    onClick={() => handleDownloadClick(orderId, "XLSX")}
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-purple-700 transition duration-300"
                    type="button"
                  >
                    {t("orderList.download")} XLSX
                  </button>
                </div>
              }
              customContent={
                <ModalButton
                  type="button"
                  color="grey"
                  onClick={() => {
                    setDowloadModal(null);
                  }}
                >
                  {t("mainButtons.close")}
                </ModalButton>
              }
            />
            <div className="flex flex-col  ">
              <div className="flex items-center flex-row text-[20px] font-bold gap-[6px] text-gray-700">
                <div className="flex flex-col">
                  <StatusBadge type={statusType.type}>
                    {`${t("documentLabels.status")} : ` + statusType.label}
                  </StatusBadge>
                  <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                    {initialValues && (
                      (() => {
                        const dateStr =
                          initialValues?.updatedAt ||
                          initialValues?.signingAt ||
                          initialValues?.createdAt;

                        if (dateStr) {
                          const date = new Date(dateStr);

                          if (!isNaN(date.getTime())) {
                            return format(date, "dd.MM.yyyy, HH:mm");
                          }
                        }

                        return "";
                      })()
                    )}
                  </div>
                </div>

                {initialValues.createdAt ? (
                  <>
                    <span>
                      {`${t("new_order.order")} ` + formik.values.number}
                    </span>
                    <span></span>
                  </>
                ) : (
                  <span>{t("new_order.create_order")}</span>
                )}
              </div>
              <div className="flex flex-col bg-white  my-5 p-5  pt-8 relative rounded-xl shadow-md gap-[20px] overflow-visible">
                <div className="flex flex-wrap justify-between gap-[20px] ">
                  <div className="flex justify-center "></div>
                  <div className="flex flex-row gap-[20px]">
                    {/* Показываем кнопки только после завершения загрузки */}
                    {!loading && (
                      <>
                        {/* Всегда показываем кнопку "Закрыть" */}
                        <button
                          className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                          type="button"
                          onClick={() => navigate(`/orders`)}
                        >
                          {t("mainButtons.close")}
                        </button>

                        {/* Проверяем статус заказа, если не IN_PROCESSING, то отображаем другие кнопки */}
                        {formik.values.status !== "IN_PROCESSING" && (
                          <>
                            {/* Статусы "new" и "DRAFT" для отображения кнопки "Сохранить" и "Отправить и Подписать" */}
                            {((formik.values.status === "new" && role.edit) ||
                      (formik.values.status == "DRAFT" && role.edit)) && (
                              <>
                                {/* Кнопка "Сохранить" */}
                                <button
                                  ref={saveButtonRef}
                                  id="saveButton"
                                  className="buttonEtrackc bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                                  type="submit"
                                  disabled={
                                    !formik.dirty ||
                                    formik.values.orderProducts?.length === 0 ||
                                    formik.values.orderProducts?.length > 30000 ||
                                    totalAmount > 30000
                                  }
                                  onClick={() => setIsFormChanged(!formik.dirty || !formik.isValid)}
                                >
                                  {t("mainButtons.save")}
                                </button>

                                {/* Кнопка "Отправить и Подписать" */}
                                {roleAcces('SIGN_BY_EDS') && (
                                  <button
                                    className="buttonEtrackc bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                                    type="button"
                                    onClick={() => submitXmlProduct()}
                                    disabled={
                                      !orderId ||
                                      formik.dirty ||
                                      formik.values.orderProducts?.length > 30000
                                    }
                                  >
                                    {t("mainButtons.sign")}
                                  </button>
                                )}
                              </>
                            )}

                            {/* Если статус не "new", не "DRAFT", показываем кнопку "Скачать" */}
                            {(daysDifference(formik?.values?.signingAt) ?? Infinity) <= 10 &&
                              formik.values.status !== "new" &&
                              roleAcces('PRINT_KM_LIST') &&
                              formik.values.status !== "DRAFT" &&
                              formik.values.status !== "ERROR" && (
                                <button
                                  className="buttonEtrackc bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                                  type="button"
                                  onClick={() => setDowloadModal(true)}
                                >
                                  {formik.values.status === "PROCESSED_PAID"
                                    ? t("orderList.repeatDownload")
                                    : t("mainButtons.download")}
                                </button>
                              )}
                          </>
                        )}
                      </>
                    )}

                    <img src="/icons/update.svg"></img>
                  </div>
                </div>

                <div className="flex gap-[20px] flex-wrap">
                  <div className={style.inputField2}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel}>
                        {t("new_order.release_method_label")}
                      </label>
                      {/* {formik.touched.refReleaseMethod &&
                        formik.errors.refReleaseMethod && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.refReleaseMethod === "string"
                              ? formik.errors.refReleaseMethod
                              : t("new_order.release_method_error")}
                          </div>
                        )} */}
                      {formik.touched.refReleaseMethod && formik.errors.refReleaseMethod && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.refCountryOrigin === "string"
                              ? formik.errors.refCountryOrigin
                              : t("new_order.country_origin_error")}
                          </div>
                        </div>
                      )}
                    </div>
                    <Field
                      value={
                        formik.values.refReleaseMethod
                          ? formik.values.refReleaseMethod.toString()
                          : ""
                      }
                      component={SelectCustomFormik}
                      name="refReleaseMethod"
                      placeholder={t("documentLabels.choose")}
                      size="sm"
                      isMulti={false}
                      options={releaseMethod}
                      isDisabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role?.edit
                      }
                      onChange={(selectedOption: any) => {
                        if (selectedOption == "1") {
                          setIsKazakhstanSelected(130);
                          formik.setFieldValue("refCountryOrigin", 130);
                        } else {
                          setIsKazakhstanSelected(false);
                        }
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>

                  <div className={style.inputField2}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel}>
                        {t("new_order.country_origin_label")}
                      </label>
                      {formik.touched.refCountryOrigin && formik.errors.refCountryOrigin && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.refCountryOrigin === "string"
                              ? formik.errors.refCountryOrigin
                              : t("new_order.country_origin_error")}
                          </div>
                        </div>
                      )}
                    </div>
                    <Field
                      value={
                        formik.values.refReleaseMethod == "1"
                          ? isKazakhstanSelected
                          : formik.values.refCountryOrigin
                      }
                      component={SelectCustomFormik}
                      name="refCountryOrigin"
                      placeholder={t("documentLabels.choose")}
                      size="sm"
                      isMulti={false}
                      options={countryOrigin}
                      isDisabled={
                        isKazakhstanSelected ||
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !roleMatrix?.roleMatrix?.find(item => item.id === 2)?.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>

                  <div className={style.inputField2}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel}>
                      {t("new_order.name_bearer")}
                      </label>
                      {formik.touched.stamp && formik.errors.stamp && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.stamp === "string"
                              ? formik.errors.stamp
                              : t("new_order.country_origin_error")}
                          </div>
                        </div>
                      )}
                    </div>
                    <Field
                      type="text"
                      name="stamp"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.stamp}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role?.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.inputField2}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel}>
                        {t("new_order.note_label")}
                      </label>
                      {formik.touched.note && formik.errors.note && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.note === "string"
                              ? formik.errors.note
                              : t("new_order.country_origin_error")}
                          </div>
                        </div>
                      )}
                    </div>
                    <Field
                      type="text"
                      name="note"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.note}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role?.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col overflow-visible bg-white pt-8 relative rounded-xl shadow-md  gap-[20px] ">
                <table className="max-w-full w-full leading-normal ">
                  <thead className="border-b-2 border-gray-300 ">
                    <tr>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      >
                        №{t("documents_upload.serialNumber")}
                      </th>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      >
                        {t("productPersonalization.productTypeCode")}
                      </th>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      >
                        {t("new_order.product_amount_label")}
                      </th>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      >
                        {t("new_order.product_weight_label")}
                      </th>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      >
                        {t("new_order.product_size_label")}
                      </th>
                      <th
                        className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                        scope="col"
                      ></th>
                    </tr>
                  </thead>

                {!loading&&(
                  formik.values.orderProducts?.length > 0 ? (
                    <tbody className="font-normal text-center">
                      {formik.values.orderProducts
                        .slice(
                          pagination.pageIndex * pagination.pageSize,
                          (pagination.pageIndex + 1) * pagination.pageSize,
                        )
                        .map((value: any, i: any) => {
                          const index =
                            pagination.pageIndex * pagination.pageSize + i;
                          const reverseIndex =
                            formik.values.orderProducts?.length -
                            (pagination.pageIndex * pagination.pageSize + i);
                          return (
                            <tr key={value.orderId}>
                              {/* Ячейки таблицы */}
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                {reverseIndex}
                              </td>
                              <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] py-0 ">
                                <div className={`${style.inputField3}`}>
                                  <Field
                                    name={`orderProducts[${index}].productTypeCode`}
                                    value={
                                      formik.values.orderProducts[index]
                                        ?.productTypeCode
                                    }
                                    placeholder={t(
                                      "documentLabels.choose_option",
                                    )}
                                    type="text"
                                    component={SelectCustomFormik}
                                    size="xl"
                                    isMulti={false}
                                    options={productTypeCode}
                                    onChange={(e: any) => {
                                      formik.handleChange(e);
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                      handleProductChange(
                                        index,
                                        "productTypeCode",
                                        e,
                                        formik,
                                      );
                                      
                                      setIsFormChanged(true);
                                    }}
                                    isDisabled={
                                      (formik.values.status !== "new" &&
                                        formik.values.status !== "DRAFT")
                                      ||
                                      !role?.edit
                                    }
                                    withoutStyle={true}
                                    className={`${formik.touched.orderProducts?.[index]
                                      ?.productTypeCode &&
                                      getTableDataErrorMessage(
                                        formik.errors,
                                        index,
                                        "productTypeCode",
                                      )
                                      ? "border-red-500  placeholder-red-500 text-center "
                                      : "border-green-300 placeholder-gray-400 text-center "
                                      } ${style.placeholder}`}
                                  />
                                </div>
                              </td>
                              <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                {/* {value?.unitsNumber || "-"} */}
                                <div className="">
                                  <Field
                                    disabled={(
                                      formik.values.status !== "new" &&
                                      formik.values.status !== "DRAFT") ||
                                      !role?.edit
                                    }
                                    type="text"
                                    name={`orderProducts[${index}].amount`}
                                    onChange={(e: any) => {
                                      formik.handleChange(e);
                                      handleProductChange(
                                        index,
                                        "amount",
                                        e.target.value,
                                        formik,
                                      );
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                    }}
                                    placeholder={
                                      formik.touched.orderProducts?.[index]
                                        ?.amount &&
                                        getTableDataErrorMessage(
                                          formik.errors,
                                          index,
                                          "amount",
                                        )
                                        ? getTableDataErrorMessage(
                                          formik.errors,
                                          index,
                                          "amount",
                                        )
                                        : t("documentLabels.choose_option")
                                    }
                                    className={`${formik.touched.orderProducts?.[index]
                                      ?.amount &&
                                      getTableDataErrorMessage(
                                        formik.errors,
                                        index,
                                        "amount",
                                      )
                                      ? "border-red-500 text-red-500 placeholder-red-500 text-center p-[20px]"
                                      : "border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"
                                      } ${style.placeholder}`}
                                  />
                                </div>
                              </td>
                              <td className="px-5  text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                {/* {value?.weight || "-"} */}
                                <div>
                                  <Field
                                    disabled={(
                                      formik.values.status !== "new" &&
                                      formik.values.status !== "DRAFT") ||
                                      !role?.edit
                                    }
                                    type="number"
                                    name={`orderProducts[${index}].weight`}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      if (
                                        value === "" ||
                                        /^\d*\.?\d{0,3}$/.test(value)
                                      ) {
                                        formik.handleChange(e);
                                        handleProductChange(
                                          index,
                                          "weight",
                                          e.target.value,
                                          formik,
                                        );
                                      }
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                    }}
                                    placeholder={t(
                                      "documentLabels.choose_option",
                                    )}
                                    className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                  />
                                </div>
                              </td>
                              <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                {/* {value?.size || "-"} */}
                                <div>
                                  <Field
                                    disabled={(
                                      formik.values.status !== "new" &&
                                      formik.values.status !== "DRAFT") ||
                                      !role?.edit
                                    }
                                    type="number"
                                    name={`orderProducts[${index}].size`}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      // Разрешаем вводить только числа и точку, ограничиваем до 3 знаков после запятой
                                      if (
                                        value === "" ||
                                        /^\d*\.?\d{0,3}$/.test(value)
                                      ) {
                                        formik.setFieldValue(
                                          `orderProducts[${index}].size`,
                                          value,
                                        );
                                        handleProductChange(
                                          index,
                                          "size",
                                          value,
                                          formik,
                                        );
                                      }
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                    }}
                                    placeholder={t(
                                      "documentLabels.choose_option",
                                    )}
                                    className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                  />
                                </div>
                              </td>
                              {(formik.values.status !== "new" &&
                                formik.values.status !== "DRAFT") ||
                                !role?.edit ? (
                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600"></td>
                              ) : (
                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                  <img
                                    src="/icons/trash.svg"
                                    className="cursor-pointer min-w-[20px]"
                                    onClick={() => {
                                      removeBlock(
                                        value.atributId,
                                        value.id,
                                        index,
                                        formik,
                                      );
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                    }}
                                  ></img>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6} className="text-center py-5">
                          <div>
                            <EmptyListPlaceholderContent
                              title={t("new_order.missing_product_message")}
                            // description={"Добавьте изделие в таблицу"}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
                )}
                  {}
                </table>

                <div className="m-[10px] flex justify-between">
                  <div className="flex  justify-center">
                    {(formik.values.status == "new" ||
                      formik.values.status == "DRAFT") &&
                      role?.edit && (
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded "
                          type="button"
                          onClick={() => {
                            addNewBlock(formik);
                            dispatch(setBlocker(true))
                            setIsFormChanged(true);
                          }}
                        >
                          {t("new_order.add_product_button")}
                        </button>
                      )}

                    <div className="my-auto">
                      {(checkNtinLength(formik.values.orderProducts) && formik.submitCount > 0) && (
                        <div className="ml-4 text-red-500">
                          {t("new_order.ntin_length_error_message")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p
                      className={`${totalAmount > 30000
                        ? "font-[400] text-red-500"
                        : "text-gray-500 font-[400]"
                        }`}
                    >
                      {t("new_order.total_products_message")}: {totalAmount} /
                      30000
                    </p>
                    <p className="text-gray-500 font-[400]">
                      {t("new_order.total_items_message")}: {totalProductsCount}
                    </p>
                  </div>
                </div>
              </div>
              {formik.values.orderProducts?.length > 0 && (
                <Pagination
                  totalItems={formik.values.orderProducts?.length}
                  totalPages={Math.ceil(
                    formik.values.orderProducts?.length / pagination.pageSize,
                  )}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              )}
            </div>
            {isLoading && <Loader />}
            <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewOrder;
