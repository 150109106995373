import axios, { AxiosResponse } from 'axios';
import i18n from '../../i18n';
import apiClient from './apiClient';

interface CreateDocRequest {
    refDocType: number;
    refCountryExport?: number;
    refCustomsClearanceDocType?: number;
    customsClearanceRegNumber?: string;
    customsClearanceRegAt?: string;
    refCustomsDecisionCode?: number;
    customsDecisionAt?: string;
    customsDecisionDocNumber?: string;
    protocolNumber?: string;
    protocolAt?: string;
    expertNumber?: string;
    expertAt?: string;
    controlActNumber?: string;
    controlActAt?: string;
    note?: string;
    documentPaperNumber?: string;
    documentPaperAt?: string;
    marks: any[];
  }
  
  export const createDocument = async (documentData: any): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await apiClient.post(
        `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/create`,
        documentData,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          },
          withCredentials: true,
        },
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error during document creation:", error);
      throw error;
    }
  };