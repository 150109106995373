import { useEffect } from "react";
import styles from "./AlertBox.module.css";
import { t } from "i18next";

interface IModalProps {
  isOpen: any;
  setIsOpen:any;
}
const replaceFieldNames = (message: string): string => {
  const fieldNamesMap: { [key: string]: string } = {
    "documentPaperAt": t('notification_from_eaes.document_date_label'),
    "customsClearanceRegNumber": t('notification_from_third_countries.customs_clearance_reg_number'),
    "customsDecisionDocNumber": t('notification_from_third_countries.customs_decision_doc_number'),
    "protocolNumber": t('notification_from_third_countries.testing_protocol_number'),
    "expertNumber": t('notification_from_third_countries.expert_opinion_number'),
    "controlActNumber": t('notification_from_third_countries.state_control_act_number'),
    "examinationReportNumber": t('notification_from_third_countries.examinationReportNumber'),
    "note": t('orderList.note'),
    "documentPaperNumber": t('notification_from_third_countries.document_paper_number'),
    "senderBinEAEU": t('error_dictionary.senderBinEAEU'),
    "manufacturerName": t('error_dictionary.manufacturerName'),
    "foundationDocumentName": t('error_dictionary.foundationDocumentName'),
    "foundationDocumentNumber": t('error_dictionary.foundationDocumentNumber')
  };
  for (const englishName in fieldNamesMap) {
    if (message.includes(englishName)) {
      message = message.replace(englishName, fieldNamesMap[englishName]);
    }
  }

  return message;
};


const AlertBox: React.FC<IModalProps> = ({ 
isOpen,
setIsOpen
}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsOpen(null);
        }, 3500); 
        return () => clearTimeout(timer); 
      }, [isOpen])

    if (!isOpen) {
      return null; 
    }
    const displayMessage = replaceFieldNames(isOpen.message);
    return (
        <div className={styles.modal}>
          <div className={styles.window}>
            <div className={styles.components}>
              <img
                src={isOpen.status === 'SUCCESS' ? "/icons/success.svg" : "/icons/warning.svg"} 
                className={styles.icon}
              />
               <span>{displayMessage}</span>
            </div>
          </div>
        </div>
      );     
};

export default AlertBox;
