import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../../../common/libs/normalizedLocale";
import style from "./index.module.css"; // Импорт модуля стилей
import classNames from "classnames";

const LandingHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const { t, i18n } = useTranslation();
  // const locale = normalizedLocale(i18n.language);
  //@ts-ignore
  const locale = normalizedLocale(i18n.translator.language);
  const mapLocaleToPath = (locale: string) => {
    return locale === "kz" ? "kk" : locale || "en"; // Упрощение кода
  };
  const changeLng = (lng: "kk" | "ru") => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setMenuOpen(false);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className={style.headerMain}>
      <nav className={style.headerNav}>
        <a href={`/`}>
          <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="Logo"
            className={style.etrackLogo}
            width={182}
            height={26}
          />
        </a>
      </nav>
      <div className={style.headerSetting}>
        {/* {["kz", "ru"].map((lang) => (
          <a
            key={lang}
            className={locale === `/${lang}` ? style.languageButtonActive : style.languageButton}
            
          >
            {lang === "kz" ? "Қаз" : lang === "ru" ? "Рус" : "Eng"}
          </a>
        ))} */}
        <button
          type="button"
          onClick={(e) => {
            changeLng("kk");
          }}
        >
          <span
            className={classNames(
              style.lngButton,
              locale === "kk" ? "font-bold" : "",
              " font-medium  p-[10px] block",
            )}
          >
            {t("common.kazakh_short")}
          </span>
        </button>
        <button
          type="button"
          onClick={(e) => {
            changeLng("ru");
          }}
          className={classNames(" font-bold")}
        >
          {" "}
          <span
            className={classNames(
              style.lngButton,
              locale === "ru" ? "font-bold" : "",
              " font-medium  p-[10px] block",
            )}
          >
            {t("common.russian_short")}
          </span>
        </button>
      </div>
    </header>
  );
};

export default LandingHeader;
