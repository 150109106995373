import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./shared/slice/authSlice";
import invoiceSlice from "./shared/slice/invoiceSlice";
import userSettings from "./shared/slice/userSettings";
import documentState from "./shared/slice/documentState";
import roleMatrix from "./shared/slice/roleMatrix";
import blockerReducer from './shared/slice/blockerSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    invoice: invoiceSlice,
    userSettings: userSettings,
    documentState:documentState,
    roleMatrix:roleMatrix,
    blocker: blockerReducer,
    // Add the generated reducer as a specific top-level slice
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
