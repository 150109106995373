import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import style from './index.module.css';
import { normalizedLocale } from '../../../../common/libs/normalizedLocale';
import { useTranslation } from 'react-i18next';

interface QrScannerComponentProps {
  onClose: () => void; // Функция для закрытия сканера
  onScanResult: (result: string) => void; // Функция для обработки результата сканирования
}

const QrScannerComponent: React.FC<QrScannerComponentProps> = ({ onClose, onScanResult }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [scanResult, setScanResult] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    codeReader.decodeFromVideoDevice(null, videoRef.current!, (result, err) => {
      if (result) {
        setScanResult(result.getText());
        onScanResult(result.getText()); // Передаем результат сканирования в родительский компонент
      }

      if (err && !(err instanceof NotFoundException)) {
        console.error(err);
      }
    });

    return () => {
      codeReader.reset(); // Сбрасываем обработчик при размонтировании компонента
    };
  }, [onScanResult]);

  return (
    <div className={style.fullscreenContainer}>
      <div className={style.topBar}>
      <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="Logo"
            className={style.etrackLogo}
            width={100}
            height={26}
          />
        <button className={style.closeButton} onClick={onClose}>
          &times;
        </button>
      </div>
      <div className={style.videoContainer}>
        <video ref={videoRef} className={style.video} />
        <div className={style.scanArea}>
          <div className={`${style.corner} ${style['top-left']}`} />
          <div className={`${style.corner} ${style['top-right']}`} />
          <div className={`${style.corner} ${style['bottom-left']}`} />
          <div className={`${style.corner} ${style['bottom-right']}`} />
        </div>
      </div>
    </div>
  );
};

export default QrScannerComponent;
