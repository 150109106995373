import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import style from "./index.module.css"; // Импорт модуля стилей
import ModalComponent from "../ModalComponent/ModalWindow";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const param = useParams();
  const [isPoliticModalOpen, setPoliticModalOpen] = useState(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modalType = urlParams.get("modal");

    if (modalType === "privacy-policy") {
      setPoliticModalOpen(true);
    } 
  }, [param, location]);

  const openModal = (modalType: string) => {
    if (modalType === "privacy-policy") {
      setPoliticModalOpen(true);
      window.history.pushState({}, "", "?modal=privacy-policy");
    }
  };

  const closeModal = () => {
    setPoliticModalOpen(false);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("modal");
    window.history.pushState({}, "", currentUrl);
  };

  return (
    <>
      <div className={style.footerBackground}>
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <div className={style.footerLeft}>
              <p className={style.footerP}>E-track © 2024</p>
            </div>
            <div className={style.footerRight}>
              <div className={style.footerLink}>
                {/* <p className={style.footerP1} onClick={() => openModal("privacy-policy")}>
                Политика конфиденциальности
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isPoliticModalOpen && (
        <ModalComponent
          headerContent={
            <div className={style.modalHeader}>
              <div className={style.modalTitle}>{t("politic")}</div>
              <img
                onClick={() => closeModal()}
                className={style.footerImg}
                src="/static/icons/close.svg"
                alt="Close"
                width={24}
                height={24}
              />
            </div>
          }
          content={<div className={style.modalBody}></div>}
          isOpen={isPoliticModalOpen}
          classNameModal={style.modalWidth}
          onClose={closeModal}
          customContent={<></>}
        />
      )} */}
      
    </>
  );
};

export default Footer;
