import React, { useEffect } from "react";
import styles from "./index.module.css";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;

  headerContent?: React.ReactNode;
  content?: React.ReactNode;
  footerContent?: React.ReactNode;
  customContent?: React.ReactNode;

  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;

  classNameTitle?: string;
  classNameContent?: string;
  classNameFooter?: string;
  classNameModal?: string;
}

const ModalWindow: React.FC<IModalProps> = ({
  isOpen,
  onClose,

  headerContent,
  content,
  footerContent,
  customContent,

  onConfirm,
  confirmText = "Принять",
  cancelText = "Отмена",

  classNameTitle,
  classNameContent,
  classNameFooter,
  classNameModal,
}) => {
  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  return isOpen ? (
    <div onClick={onClose} className={styles.modal}>
      {/* Контент */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${styles.modalWrapper}  ${classNameModal ?? ""}`}
      >
        {/* <header className={classNameTitle || "modal__title"}>
          {headerContent}
        </header> */}
        <div className={classNameContent || ""}>{content}</div>
        <footer className={classNameFooter || styles.footer}>
          {footerContent && (
            <>
              <button className={styles.button} onClick={onClose}>
                {cancelText}
              </button>
              {footerContent}
            </>
          )}
          {/* {!footerContent && !customContent && (
            <ModalButton color="green" onClick={onClose}>
              {confirmText}
            </ModalButton>
          )} */}
          {customContent && <>{customContent}</>}
        </footer>
      </div>
    </div>
  ) : null;
};

export default ModalWindow;
