import axios from 'axios';
import i18n from 'i18next'; // для работы с Accept-Language, если нужно
import Cookies from 'js-cookie';
import { refreshAccessToken } from './authApi';


const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // установи базовый URL, если все запросы идут с одного домена
  withCredentials: true, // чтобы автоматически отправлялись куки
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Интерсептор для обработки ответов и обновления токена
apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    // Проверка на истечение сессии
    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then(() => {
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refresh_token = Cookies.get("refresh_token");

      if (refresh_token) {
        return new Promise((resolve, reject) => {
          refreshAccessToken(refresh_token)
            .then((res) => {
              Cookies.set("access_token", res.data.access_token);
              Cookies.set("refresh_token", res.data.refresh_token);
              Cookies.set("token_expiry", String(res.data.refresh_expires_in + Math.floor(Date.now() / 1000)));
              processQueue(null);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  }
);

// Интерсептор для каждого запроса, если нужно установить языковые настройки
apiClient.interceptors.request.use(
  (config) => {
    // Устанавливаем Accept-Language для всех запросов
    config.headers['Accept-Language'] = i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ";
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
