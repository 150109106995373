import axios from "axios";
import i18n from "../../i18n";
import apiClient from "./apiClient";


interface DocumentXMLResponse {
  status: string;
  message: string;
  data: any;  // Типизация может быть более детализирована в зависимости от структуры данных
}

export const getRevokeAct = async (docId: any): Promise<DocumentXMLResponse> => {
  try {
    const response = await apiClient.get(`${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/get/xml/${docId}?doc_operation=WITHDRAW`, {
      headers: {
        "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
      },
      withCredentials: true
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching document XML for signing:", error);
    throw error;
  }
};


interface DocumentSignedResponse {
    status: string;
    message: string;
    data: any;  // Типизация может быть более детализирована в зависимости от структуры данных
  }
  
  export const postRevokeAct = async (docId: any, signedXML: string): Promise<DocumentSignedResponse> => {
    try {
      const requestBody = {
        signedXML
      };
      const response = await apiClient.post(`${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/revoke-act/${docId}`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true
      });
  
      return response.data;
    } catch (error) {
      console.error("Error during saving signed document XML:", error);
      throw error;
    }
  };