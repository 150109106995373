import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import style from "./ChangingLanguage.module.css";
import classNames from "classnames";
import { useEffect, useState } from "react";

interface IChangingLanguage {
  onlyDesktop?: boolean;
}

export const ChangingLanguage: React.FC<IChangingLanguage> = ({
  onlyDesktop = false,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  //@ts-ignore
  const locale = normalizedLocale(i18n.translator.language);

  const changeLng = (lng: "kk" | "ru") => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
  };

  // useEffect(() => {
  //     if (locale !== 'kk' || locale !== 'ru' || locale !== 'en') {
  //         const lngLocal = String(
  //             localStorage.getItem('i18nextLng'),
  //         ) as string;

  //         //@ts-ignore
  //         if (lngLocal === 'kk' || lngLocal === 'ru' || lngLocal === 'en') {
  //             localStorage.setItem('i18nextLng', lngLocal);
  //             i18n.changeLanguage(lngLocal);
  //         } else {
  //             localStorage.setItem('i18nextLng', 'kk');
  //             i18n.changeLanguage('kk');
  //         }
  //     }
  // }, []);

  return (
    <div className="">
      <div
        className={classNames(
          onlyDesktop ? "hidden" : "flex gap-4   md:hidden",
        )}
      >
        <button
          type="button"
          onClick={(e) => {
            changeLng("kk");
          }}
        >
          <span
            className={classNames(
              style.lngButton,
              locale === "kk" ? style.lngActive : "",
              " font-medium  p-[10px] block",
            )}
          >
            {t("common.kazakh_short")}
          </span>
        </button>
        <button
          type="button"
          onClick={(e) => {
            changeLng("ru");
          }}
          className={classNames(" font-bold")}
        >
          {" "}
          <span
            className={classNames(
              style.lngButton,
              locale === "ru" ? style.lngActive : "",
              " font-medium  p-[10px] block",
            )}
          >
            {t("common.russian_short")}
          </span>
        </button>
      </div>
      <button
        type="button"
        data-tooltip-id="changing-language"
        className={classNames(
          "h-6 text-sm items-center gap-1",
          onlyDesktop ? "flex" : "hidden md:flex ",
        )}
        data-tooltip-offset={24}
      >
        {locale === "kk" ? "Қаз" : "Rus"}
        <img
          src="/icons/arrow-top.svg"
          className={classNames(
            "block h-6 w-6 duration-200",
            open ? "" : "rotate-180",
          )}
        />
      </button>

      <div className="z-[1000] relative	">
        <Tooltip
          id="changing-language"
          className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => {
            setOpen(true);
          }}
          afterHide={() => {
            setOpen(false);
          }}
        >
          <div
            className={classNames(
              style.tooltip,
              "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
            )}
          >
            <span className={classNames("flex h-10 items-center pl-4 text-sm")}>
              {t("authorization.selectLanguage")}
            </span>
            <button
              type="button"
              className={classNames(
                "flex h-10 items-center px-4 duration-200 text-sm justify-between",
              )}
              onClick={(e) => {
                changeLng("ru");
              }}
            >
              <span>{t("common.russian")}</span>
              {locale === "ru" && <img src="/icons/check.svg" alt="" />}
            </button>
            <button
              type="button"
              className={classNames(
                "flex h-10 items-center px-4 duration-200 text-sm justify-between",
              )}
              onClick={(e) => {
                changeLng("kk");
              }}
            >
              <span>{t("common.kazakh")}</span>
              {locale === "kk" && <img src="/icons/check.svg" alt="" />}
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
