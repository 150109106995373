import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBlocked: false,
  reset: null,
  proceed: null,
};

const blockerSlice = createSlice({
  name: 'blocker',
  initialState,
  reducers: {
    setBlocker: (state, action) => {
      state.isBlocked = true;
      state.reset = action.payload.reset || null;
      state.proceed = action.payload.proceed || null;
    },
    clearBlocker: (state) => {
      state.isBlocked = false;
      state.reset = null;
      state.proceed = null;
    },
  },
});

export const { setBlocker, clearBlocker } = blockerSlice.actions;

export default blockerSlice.reducer;
