import {
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  getIn,
} from "formik";
import {login} from "../../../shared/slice/authSlice";
import style from "./ProductPersonalization.module.css";
import { useEffect, useState } from "react";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import Stepper from "../../../shared/components/Stepper/Stepper";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import { t } from "i18next";
import { format } from "date-fns";
import { Navigate, useBlocker, useNavigate, useParams } from "react-router-dom";
import { EmptyListPlaceholderContent } from "../../../shared/Ui/EmptyPageBlock";
import SelectCustomFormik from "../../../shared/Ui/SelectCustom/SelectCustomFormik";
import { nanoid } from "nanoid";
import Pagination from "../../../shared/components/Table/Pagination";
import Loader from "../../../shared/Ui/Loader/Loader";
import { useTranslation } from "react-i18next";
import DocumentUpload from "../Upload/DocumentUpload/DocumentUpload";
import DocumentsService, { editDocument } from "../../../common/api/documentsApi";
import { createDocument } from "../../../common/api/notificationApi";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { getNtinCodes, getOrderProducts } from "../../../common/api/ordersApi";
import {
  addProductsPersonalization,
  addProductsToDocument,
  deletePersonalization,
  editDocumentPersonalization,
  getNtinCodesByCode,
  getPersonalizationProductsDoc,
  getPersonalizationProductsManual,
} from "../../../common/api/nktApi";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import PersonalizationUpload from "./utils/persalizationUpload/PersalizationUpload";
import * as Yup from "yup";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import {
  getDocumentXMLForPersSigning,
  saveSignedDocumentXML,
} from "../../../common/api/documentSignature";
import CheckResult from "../Upload/CheckResults/CheckResult";
import CheckResultPersonalization from "./utils/checkResultSign/CheckResultPersanalization";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import { Tooltip } from "react-tooltip";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";

function ProductPersonalization() {
  interface NtinCodeObject {
    [key: string]: any;
  }
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  const { t, i18n } = useTranslation();
  const [ntinCodeObject, setNtinCodeObject] = useState<NtinCodeObject>({});
  const locale = normalizedLocale(i18n.language);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [stackDelete, setStackDelete] = useState<any>([]);
  const [stackEdit, setStackEdit] = useState<any>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [stackAddCount, setStackAddCount] = useState<any>(0);
  const { ProductPersonalizationId } = useParams();
  const [dowloadFromDock, setDowloadFromDock] = useState(true);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [toggleOnLoad, setToggleOnLoad] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [fullPersonalizationPromlem, setFullPersonalizationPromlem] =
    useState<any>(null);
  const [fullFieldPromlem, setFullFieldPromlem] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [pagination, setPagination] = useState<any>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    id: "",
    org_id: "generatedOrgId", // ID организации
    create_user_id: "generatedUserId", // ID создавшего пользователя
    createdAt: "", // Дата и время создания
    updatedAt: "timestamp", // Дата и время последнего изменения
    signingAt: "timestamp", // Дата и время подписания
    status: "new", // Статус, например "Обработан", "Ошибочный" и т.д.
    refReleaseMethod: undefined, // Способ выпуска в оборот, ID метода
    refCountryOrigin: undefined, // Страна производства, ID страны
    note: "", // Примечание
    error_reason: "", // Причина ошибки
    number: "", // Регистрационный номер
    orderProducts: [],
  });
  const dispatch = useAppDispatch();
  const [ntinTypeCode, setNtinTypeCode] = useState<any[]>([]);
  const [productTypeCode, setProductTypeCode] = useState<any[]>([]);
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const checkNtinLength = (orderProducts: any) => {
    return orderProducts.every((product: any) => product.ntin?.length === 14);
  };
  

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  interface NtinCodeData {
    ntin: string;
    nameRu: string;
    nameKz: string;
    // Добавьте другие поля, если они есть в ответе
  }

  interface OrderProduct {
    productTypeCode: string;
    ntin: string;
  }

  interface FormValues {
    orderProducts: OrderProduct[];
  }
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Проверяем состояние кнопки "Сохранить"
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена
      currentLocation.pathname !== nextLocation.pathname // и если путь меняется
  );
  

  const cache: { [key: string]: Promise<NtinCodeData[]> | NtinCodeData[] } = {};

  const onLoad = () => {
    if (!ProductPersonalizationId) return Promise.resolve();
    setIsLoading(true);

    return DocumentsService.getDocument(ProductPersonalizationId)
      .then((documentResponse) => {
        setDowloadFromDock(!documentResponse.data.isManualBinding);

        if (documentResponse && documentResponse?.data) {
          const fetchProducts = (docId: any) => {
            if (!documentResponse.data.isManualBinding) {
              return getPersonalizationProductsDoc(docId);
            } else {
              return getPersonalizationProductsManual(docId);
            }
          };

          fetchProducts(ProductPersonalizationId)
            .then((productsResponse) => {
              // Создаем объект для хранения всех NTIN
              const ntinObject = productsResponse.products.reduce(
                (acc: any, product: any) => {
                  acc[product.ntin] = true; // Просто сохраняем NTIN как ключ
                  return acc;
                },
                {},
              );

              // Обновляем состояние ntinCodeObject
              setNtinCodeObject(ntinObject);

              setInitialValues((prevValues: any) => ({
                ...documentResponse.data,
                orderProducts: productsResponse.products || [],
              }));

              const productTypeCodes = (productsResponse.products || []).map(
                (product: any) => product.productTypeCode,
              );

              const fetchCodesPromises = productTypeCodes.map(
                (productTypeCode: string, index: number) => {
                  if (cache[productTypeCode]) {
                    return Promise.resolve(cache[productTypeCode]).then(
                      (cachedData) => {
                        const options = cachedData.map(
                          (item: NtinCodeData) => ({
                            label: `${item.ntin}`,
                            value: item.ntin,
                          }),
                        );

                        setNtinTypeCode((prev: any) => {
                          const newTypeCode = [...prev];
                          newTypeCode[index] = options;
                          return newTypeCode;
                        });
                      },
                    );
                  }

                  const fetchPromise = getNtinCodesByCode(productTypeCode)
                    .then((response: any) => {
                      if (response && response.data) {
                        const options = response.data.map(
                          (item: NtinCodeData) => ({
                            label: `${item.ntin} ${locale === "ru" ? item.nameRu : item.nameKz
                              }`,
                            value: item.ntin,
                          }),
                        );

                        cache[productTypeCode] = response.data;

                        setNtinTypeCode((prev: any) => {
                          const newTypeCode = [...prev];
                          newTypeCode[index] = options;
                          return newTypeCode;
                        });

                        return response.data;
                      }
                    })
                    .catch((error: any) => {
                      console.error("Ошибка при получении кодов NTIN:", error);

                      setNtinTypeCode((prev: any) => {
                        const newTypeCode = [...prev];
                        newTypeCode[index] = [];
                        return newTypeCode;
                      });

                      return [];
                    });

                  cache[productTypeCode] = fetchPromise;

                  return fetchPromise;
                },
              );

              console.log(ntinObject, "console.log(ntinObject);");

              Promise.all(fetchCodesPromises)
                .then(() => {
                  setIsLoading(false);
                })
                .catch((error) => {
                  setAlertOpen({
                    status: "error",
                    message: error?.response?.data?.message
                      ? error?.response?.data?.message
                      : "Ошибка при выполнении запросов на получение кодов NTIN:",
                  });
                  console.error(
                    "Ошибка при выполнении запросов на получение кодов NTIN:",
                    error,
                  );
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              setAlertOpen({
                status: "error",
                message: error?.response?.data?.message
                  ? error?.response?.data?.message
                  : t("documentNotification.document_save_error"),
              });
              console.error("Ошибка при загрузке изделий:", error);
              setIsLoading(false);
            });
        } else {
          setAlertOpen({
            status: "error",
            message: "Документ не найден или данные недоступны.",
          });
          console.log("Документ не найден или данные недоступны.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setAlertOpen({
          status: "error",
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("documentNotification.document_save_error"),
        });
        console.error("Ошибка при загрузке информации ", error);
        navigate("/404");
        setIsLoading(false);
      });
  };
console.log('login11',isLogin);

  useEffect(() => {
    const roleObj = () => {
      return {
        roleDraft: roleMatrix?.roleMatrix?.find((item) => item.id === 7),
      };
    };

    setRole(roleObj);

    if (ProductPersonalizationId !== undefined) {
      onLoad().then(() => {
        // if (documentState.isSafe) {
        //   setIsFormChanged(true);
        //   setInitialValues((prev: any) => {
        //     return { ...prev, ...documentState.documentData };
        //   });
        // }
      });
    } else {
      setIsLoading(false);
    }

    getNtinCodes().then((response: any) => {
      const options = response.map((item: any) => ({
        label: `${item.name}: ${item.productTypeCode}`,
        value: item.productTypeCode,
      }));
      setProductTypeCode(options);
    });
  }, [ProductPersonalizationId]);

  const addNewBlock = (formik: any) => {
    const orderProducts: any = {
      orderId: nanoid(),
      productTypeCode: "",
      ntin: "",
      name: "",
      amount: "1",
      weight: "",
      size: "",
    };

    if (ProductPersonalizationId) {
      setStackAddCount((prevStack: any) => prevStack + 1);
    }

    const newTable = [orderProducts, ...formik.values.orderProducts];
    formik.setFieldValue("orderProducts", newTable);
    setNtinTypeCode((prev) => {
      return [[], ...prev];
    });
    formik.resetForm({
      values: {
        ...formik.values,
        orderProducts: newTable,
      },
    });
  };

  const ntinCodesByCode = (ntin: any, index: number, formik: any) => {
    // Очистка текущего значения NTIN перед запросом
    formik.setFieldValue(`orderProducts[${index}].ntin`, null);
    console.log(21212112, formik.values.orderProducts[index]);

    // Выполнение запроса
    getNtinCodesByCode(ntin)
      .then((response: any) => {
        if (response && response.data) {
          const options = response.data.map((item: any) => ({
            label: `${item.ntin} ${locale === "ru" ? item.nameRu : item.nameKz}`,
            value: item.ntin,
          }));

          // Клонирование массива и обновление только нужного индекса
          setNtinTypeCode((prev) => {
            const newTypeCode = [...prev];
            newTypeCode[index] = options;
            return newTypeCode;
          });
        }
      })
      .catch((error) => {
        setNtinTypeCode((prev) => {
          const newTypeCode = [...prev];
          newTypeCode[index] = [];
          return newTypeCode;
        });
      });
  };

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue?.length < 3 && ntinTypeCode?.length === 0) {
      return t("введите Нтин");
    } else {
      return t("documents_orders.no_data_title");
    }
  };

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: "documentStatus.error" };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  const handleProductChange = (
    index: number,
    field: string,
    value: any,
    formik: any,
  ) => {
    if (formik.values.orderProducts[index].id) {
      setStackEdit((prev: any) => {
        const updatedProducts = [...prev];
        const productIndex = updatedProducts.findIndex(
          (product) => product.id === formik.values.orderProducts[index].id,
        );
        if (productIndex >= 0) {
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            [field]: value,
          };
        } else {
          updatedProducts.push({
            ...formik.values.orderProducts[index],
            [field]: value,
          });
        }
        console.log(updatedProducts, "updatedProducts");
        return updatedProducts;
      });
    }
  };

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      case 6:
        return t("submenu.personalization");
      default:
        return t("documentType.unknown");
    }
  }

  // Подписание заявки
  async function submitXmlProduct() {
    const ncalayerClient = new NCALayerClient();
    try {
      await ncalayerClient.connect();
    } catch (error: any) {
      setAlertOpen({
        status: "error",
        message: t("documentNotification.nca_no_connect"),
      });
      return;
    }

    let xml;
    try {
      setIsLoading(true);
      xml = await getDocumentXMLForPersSigning(ProductPersonalizationId);
      console.log("xml", xml);
      if (!xml || !xml.data) {
        console.log("Не удалось получить XML документ для подписи.");
        return;
      }
      if (xml.data.errorFields && xml.data.errorFields?.length > 0) {
        const allMarks = xml.data.errorFields.flatMap(
          (product: any) => product.marks,
        );
        setFullFieldPromlem({
          marks: allMarks,
          full: xml.data.errorFields,
        });
        return;
      }
      if (xml.data.problemMarks && xml.data.problemMarks?.length > 0) {
        const allMarks = xml.data.problemMarks.flatMap(
          (product: any) => product.marks,
        );
        setIsCheckOpen(allMarks);
        setFullPersonalizationPromlem(xml.data.problemMarks);
        console.log("xml2", xml.data.problemMarks);
        return;
      }
      if (xml.data.problemProducts && xml.data.problemProducts?.length > 0) {
        setFullFieldPromlem({
          full: xml.data.problemProducts,
        });
        return;
      }
    } catch (error: any) {
      setErrorModal({
        singleError: true,
        errorMessage: error.response.data.message
          ? error.response.data.message
          : t("documentNotification.unknown_error"),
      });
      return;
    } finally {
      setIsLoading(false);
    }
    let xmlSignature;
    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml.data.xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature[0]);
    } catch (error: any) {
      // setErrorModal(error.data.message);
      return;
    }

    try {
      setIsLoading(true);
      const documentIdValue = ProductPersonalizationId ?? "0";
      await saveSignedDocumentXML(documentIdValue, xmlSignature[0]);
      alert(t("documentNotification.document_xml_success"));
      navigate(`/documents/personalization`);
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      console.log("e", e);
      const errorMessage = e.response?.data;
      alert(t("documentNotification.document_xml_error"));
      console.log("errorka", errorMessage);
      setErrorModal({
        singleError: true,
        errorMessage: errorMessage?.message
          ? errorMessage?.message
          : t("documentNotification.unknown_error"),
      });
    } finally {
      setIsLoading(false);
    }
  }


  async function submitCreate(formikValues: any, ntin?: any) {
    dispatch(clearBlocker())
    const newProducts = Array.isArray(formikValues?.orderProducts)
      ? formikValues.orderProducts.slice(0, stackAddCount)
      : [];
    const backendData: any = {
      refDocType: 6,
      isManualBinding: !dowloadFromDock,
      id: ProductPersonalizationId || null,
    };
    if (ProductPersonalizationId !== undefined) {
      if (dowloadFromDock === initialValues.isManualBinding) {
        await editDocument(backendData)
      }
      if (stackEdit?.length > 0) {
        await editDocumentPersonalization(ProductPersonalizationId, stackEdit)
          .then(() => {
            setStackEdit([])
            dispatch(clearBlocker())
            setIsFormChanged(false);
            setAlertOpen({
              status: "SUCCESS",
              message: t("documentNotification.document_edited_success"),
            });
          })
          .catch((error) => {
            console.error("Error creating new invoice:", error);
            setAlertOpen({
              status: "error",
              message: t("documentNotification.document_edit_error"),
            });
          })
      }

      if (stackDelete?.length > 0) {
        await deletePersonalization(ProductPersonalizationId, stackDelete)
          .then((data: any) => setStackDelete([]))
          .catch((error: any) => {
            setAlertOpen({
              status: error,
              message: t("documentsError.order_delete_error"),
            });
          }).finally(async () => {
            if (newProducts?.length > 0) {
              await addProductsPersonalization(ProductPersonalizationId, newProducts)
                .then(() => setStackAddCount([]))
                .catch((error) => {
                  setAlertOpen({
                    status: error,
                    message: t("documentsError.order_add_error"),
                  });
                });
            }
          })
      }

      if (newProducts?.length > 0 && stackDelete?.length == 0) {
        await addProductsPersonalization(ProductPersonalizationId, newProducts)
          .then(() => setStackAddCount([]))
          .catch((error) => {
            setAlertOpen({
              status: error,
              message: t("documentsError.order_add_error"),
            });
          });
      }

      if (ntin) {
        getPersonalizationProductsManual(ProductPersonalizationId).then(
          (response) => {
            const foundProduct = response.products.find(
              (product: any) => product.ntin === ntin,
            );
            if (foundProduct) {
              navigate(`${foundProduct.id}`);
            }
          },
        );
      } else {
        onLoad();
      }
    } else {
      await createDocument(backendData)
        .then(async (response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          navigate(`/documents/personalization/${response.data.document.id}`);

          if (formikValues?.orderProducts.length > 0) {
            await addProductsToDocument(response.data.document.id, {
              products: [...formikValues?.orderProducts],
            });
          }
          if (ntin >= 0) {
            getPersonalizationProductsManual(response.data.document.id).then(
              (response) => {
                const foundProduct = response.products.find(
                  (product: any) => product.ntin === ntin,
                );
                if (foundProduct) {
                  navigate(`${foundProduct.id}`);
                }
              },
            );
          }
          navigate(`/documents/personalization/${response.data.document.id}`);
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: t("documentNotification.document_save_error"),
          });
        });
    }
  }

  const handleRemove = (
    atributId: number,
    id: number,
    index: number,
    formik: any,
  ) => {
    removeBlock(atributId, id, index, formik);

    setNtinCodeObject((prevObject) => {
      const updatedObject = { ...prevObject };
      const ntinKey = formik.values.orderProducts[index]?.ntin;
      if (ntinKey && updatedObject.hasOwnProperty(ntinKey)) {
        delete updatedObject[ntinKey];
      }

      return updatedObject;
    });
    dispatch(setBlocker(true))
    setIsFormChanged(true);
    console.log(ntinCodeObject, "ntinCodeObject");
  };

  const removeBlock = (
    atributId: number,
    id: number,
    index: number,
    formik: any,
  ) => {
    const newTable = formik.values.orderProducts.filter(
      (_: any, i: any) => i !== index,
    );

    // Обновление списка опций
    setNtinTypeCode((prev: any) => {
      const newTypeCode = [...prev];
      newTypeCode.splice(index, 1); // Удаление опций для удаленной строки
      return newTypeCode;
    });

    if (ProductPersonalizationId) {
      setStackDelete((prevStack: any) => [...prevStack, id]);
    }

    if (ProductPersonalizationId && !id) {
      setStackAddCount((prevStack: any) => prevStack - 1);
    }

    formik.setFieldValue("orderProducts", newTable);
  };

  if (isLoading && !role) return <Loader />;

  if (!role || !role?.roleDraft?.view) {
    return <PageNotRole />;
  }

  interface FormErrors {
    [key: string]: string;
  }

  const validationSchema = Yup.object({
    orderProducts: Yup.array()
      .of(
        Yup.object({
          productTypeCode: Yup.string().required(
            "Поле обязательно для заполнения",
          ),
          ntin: Yup.string().required("Поле обязательно для заполнения"),
        }),
      )
      .required("Необходимо заполнить все продукты"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues) => console.log(123)}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const isAnyMarksAmountPresent = formik.values.orderProducts.some(
          (product: any) => product?.marksAmount && product.marksAmount > 0
        );
        console.log(1122, isAnyMarksAmountPresent);
        // Устанавливаем текущий шаг в зависимости от условия
        const currentStep = isAnyMarksAmountPresent ? 3 : 1;
        return (
          <Form className="max-w-[1585px] w-full m-t-[20px]">
            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
                <div>
                  {ProductPersonalizationId ? (
                    <div className="flex gap-[20px]">
                      <div className="flex flex-col">
                        <StatusBadge type={statusType?.type}>
                          {t("documentLabels.status") +
                            ": " +
                            statusType?.label}
                        </StatusBadge>
                        {initialValues?.createdAt && (
                          <div className="text-[14px] font-semibold text-gray-700">
                            {" " + " "}
                            {format(
                              new Date(initialValues?.createdAt),
                              "dd.MM.yyyy, HH:mm",
                            )}
                          </div>
                        )}
                      </div>

                      <span className="flex gap-[10px]">
                        <div>{getDescription(formik?.values?.refDocType)}</div>
                        <div>
                          {formik?.values?.docNumber
                            ? formik?.values?.docNumber
                            : " не сформирован"}
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="flex gap-[20px]">
                      <StatusBadge type={statusType?.type}>
                        {t("documentLabels.status") + " " + statusType?.label}
                      </StatusBadge>
                      <div>
                        {t("documentLabels.create_document")}
                        {": "}
                        {t("productPersonalization.title")}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" w-full flex justify-start items-center gap-[20px]">
                {/* Радиокнопка "Ручная привязка" */}

                <div
                  onClick={() => {
                    formik.values.orderProducts.length == 0
                      ? setDowloadFromDock(true)
                      : {};
                  }}
                  className={`flex gap-[10px] items-center cursor-pointer  ${dowloadFromDock ? " text-green" : " text-gray-700"}`}
                  data-tooltip-id="tooltipdoc"
                  data-tooltip-content={t("productPersonalization.no_toggle_tooltip")}
                >
                  {(formik.values.orderProducts.length > 0 && !dowloadFromDock) && (
                    <Tooltip id="tooltipdoc" place="top" delayHide={1500} />
                  )}
                  <div
                    className={`w-[18px] h-[18px] border  rounded-full flex items-center justify-center ${dowloadFromDock ? "border-green" : "border-gray-300"}`}
                  >
                    <div
                      className={`w-2 h-2 rounded-full ${dowloadFromDock ? "bg-green" : "bg-gray-300"}`}
                    ></div>
                  </div>
                  <span>{t("productPersonalization.download_fromdoc")}</span>
                </div>

                {/* Радиокнопка "Загрузка из документа" */}

                <div
                  onClick={() => {
                    formik.values.orderProducts.length == 0
                      ? setDowloadFromDock(false)
                      : {};
                  }}
                  className={`flex  gap-[10px] items-center cursor-pointer  ${!dowloadFromDock ? " text-green" : " text-gray-700"}`}
                  data-tooltip-id="tooltipmanual"
                  data-tooltip-content={t("productPersonalization.no_toggle_tooltip")}
                >
                  {(formik.values.orderProducts.length > 0 && dowloadFromDock) && (
                    <Tooltip id="tooltipmanual" place="top" delayHide={1500} />
                  )}
                  <div
                    className={`w-[18px] h-[18px] border  rounded-full flex items-center justify-center ${!dowloadFromDock ? "border-green" : "border-gray-300"}`}
                  >
                    <div
                      className={`w-2 h-2 rounded-full ${!dowloadFromDock ? "bg-green" : "bg-gray-300"}`}
                    ></div>
                  </div>
                  <span>{t("productPersonalization.handle_bind")}</span>
                </div>
              </div>

              <div className="flex flex-row flex-wrap justify-between ">
                <div className="my-auto">
                  {!dowloadFromDock ? (
                    <Stepper
                      steps={[
                        t("productPersonalization.productGroups"),
                        t("productPersonalization.brands"),
                        t("productPersonalization.signature"),
                      ]}
                      currentStep={currentStep}
                    />
                  ) :
                    (<Stepper
                      steps={[
                        t("components.items"),
                        t("productPersonalization.signature"),
                      ]}
                      currentStep={formik.values.orderProducts.length > 0 ? 2 : 1}
                    />)
                  }

                </div>
                <div className="flex flex-row gap-[20px] flex-wrap">

                  <div className="flex flex-row gap-[20px] flex-wrap">

                    <button
                      className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        initialValues.status == "DRAFT"
                          ? navigate("/documents/drafts")
                          : navigate("/documents/personalization");
                        console.log(123, formik.values.orderProducts);
                      }}
                    >
                      {t("mainButtons.close")}
                    </button>
                    {((formik.values.status === "new" &&
                      role.roleDraft.edit) ||
                      (formik.values.status == "DRAFT" &&
                        role.roleDraft.edit)) && (
                        <>
                          <button
                            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                            type="submit"
                            // onClick={() => {
                            //   // dispatch(deleteDataDocument());
                            //   submitCreate(formik?.values);
                            //   setIsSubmitted(true)
                            // }}
                            onClick={async () => {
                              dispatch(clearBlocker())
                              setIsFormChanged(false)
                              // Программно запускаем сабмит формы
                              const isValid = await formik.validateForm(); // Проверяем, валидна ли форма
                              if (Object.keys(isValid).length === 0) {
                                await formik.submitForm(); // Сабмит формы
                                submitCreate(formik.values); // Выполняем кастомное действие
                                setIsSubmitted(true); // Устанавливаем флаг отправки
                              }
                            }}
                            disabled={formik.values.orderProducts.length < 1 && !role.roleDraft.edit}
                          >

                            {t("mainButtons.save")}
                          </button>
                        </>
                      )}
                  </div>

                  {
                    (initialValues.status !== 'PROCESSED' && initialValues.status !== 'new') && role.roleDraft.edit && (
                      <button
                        className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                        type="button"
                        disabled={formik.values.orderProducts.length < 1}
                        onClick={() => {
                          submitXmlProduct();
                        }}
                      >
                        {t("mainButtons.sign")}
                      </button>
                    )
                  }

                </div>
              </div>
              <div className="flex flex-col overflow-visible bg-white pt-8 relative rounded-xl shadow-md  gap-[20px] ">
                <table className="max-w-full w-full leading-normal ">
                  <thead className="border-b-2 border-gray-300 ">
                    <tr>
                      {dowloadFromDock ? (
                        <>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            №{t("documents_upload.serialNumber")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            Код
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.productTypeCode")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.ntin")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.weight")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.size")}
                          </th>
                        </>
                      ) : (
                        <>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            №{t("documents_upload.serialNumber")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.productTypeCode")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.ntin")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.orderName")}
                          </th>
                          <th
                            className="px-5 py-3 text-gray-600 font-semibold bg-white border-b border-gray-200 text-center"
                            scope="col"
                          >
                            {t("productPersonalization.insertCount")}
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  {formik.values.orderProducts?.length > 0 ? (
                    <tbody className="font-normal text-center">
                      {formik.values.orderProducts
                        .slice(
                          pagination.pageIndex * pagination.pageSize,
                          (pagination.pageIndex + 1) * pagination.pageSize,
                        )
                        .map((value: any, i: any) => {
                          const index =
                            pagination.pageIndex * pagination.pageSize + i;
                          const reverseIndex =
                            formik.values.orderProducts?.length -
                            (pagination.pageIndex * pagination.pageSize + i);
                          const isProductTypeCodeSelected =
                            !!formik.values.orderProducts[index]
                              ?.productTypeCode;
                          const isNTINSelected =
                            !!formik.values.orderProducts[index]?.ntin;
                          return (
                            <tr key={value.orderId}>
                              {/* Ячейки таблицы */}
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center w-[80px]">
                                {reverseIndex}
                              </td>
                              {!dowloadFromDock ? (
                                <>
                                  <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] w-[270--px] py-0 ">
                                    <div className={`${style.inputField3}`}>
                                      <Field
                                      noOptionsMessage={noOptionsMessage}
                                        name={`orderProducts[${index}].productTypeCode`}
                                        value={
                                          formik.values.orderProducts[index]
                                            ?.productTypeCode || ""
                                        }
                                        className={`
                                      ${getIn(
                                          formik.errors,
                                          `orderProducts[${index}].productTypeCode`,
                                        )
                                            ? "placeholder-red-500"
                                            : "placeholder-gray-400"
                                          }  
                                      `}
                                        placeholder={
                                          getIn(
                                            formik.touched,
                                            `orderProducts[${index}].productTypeCode`,
                                          ) &&
                                            getIn(
                                              formik.errors,
                                              `orderProducts[${index}].productTypeCode`,
                                            )
                                            ? (getIn(
                                              formik.errors,
                                              `orderProducts[${index}].productTypeCode`,
                                            ) as string)
                                            : t("documentLabels.choose_option")
                                        }
                                        type="text"
                                        component={SelectCustomFormik}
                                        size="xl"
                                        isMulti={false}
                                        options={productTypeCode}
                                        // onInputChange={() => ntinCodesByCode(formik.values.orderProducts[index].productTypeCode, formik, index)}
                                        onChange={(e: any) => {
                                          console.log(e, "111");
                                          handleProductChange(
                                            index,
                                            "productTypeCode",
                                            e,
                                            formik,
                                          );
                                          ntinCodesByCode(e, index, formik);
                                          formik.handleChange(e);
                                          setIsFormChanged(true);
                                          dispatch(setBlocker(true))
                                        }}
                                        // isDisabled={
                                        //   (formik.values.status !== "new" &&
                                        //     formik.values.status !== "DRAFT") ||
                                        //   !role.roleDraft.edit
                                        // }
                                        isDisabled={
                                          (formik.values.status !== "new" &&
                                            formik.values.status !== "DRAFT") ||
                                          formik.values.orderProducts[index]
                                            ?.marksAmount > 0 || !role.roleDraft.edit
                                        }
                                        isError={
                                          getIn(
                                            formik.touched,
                                            `orderProducts[${index}].productTypeCode`,
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `orderProducts[${index}].productTypeCode`,
                                          )
                                        }
                                        withoutStyle={true}
                                      />
                                    </div>
                                  </td>
                                  <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center box-content w-[280px]">
                                    {/* {value?.unitsNumber || "-"} */}
                                    <div className={`${style.inputField3}`}>
                                      <Field
                                        name={`orderProducts[${index}].ntin`}
                                        value={
                                          formik.values.orderProducts[index]
                                            ?.ntin
                                        }
                                        placeholder={
                                          getIn(
                                            formik.touched,
                                            `orderProducts[${index}].ntin`,
                                          ) &&
                                            getIn(
                                              formik.errors,
                                              `orderProducts[${index}].ntin`,
                                            )
                                            ? (getIn(
                                              formik.errors,
                                              `orderProducts[${index}].ntin`,
                                            ) as string)
                                            : t("documentLabels.choose_option")
                                        }
                                        type="text"
                                        component={SelectCustomFormik}
                                        size="xl"
                                        isMulti={false}
                                        options={
                                          ntinTypeCode[index]
                                            ?.filter((option: any) => {
                                              const currentNtin =
                                                formik.values.orderProducts[
                                                  index
                                                ]?.ntin;
                                              return (
                                                option.value === currentNtin ||
                                                !ntinCodeObject.hasOwnProperty(
                                                  option.value,
                                                )
                                              );
                                            })
                                            .map((option: any) => {
                                              // Разделяем label на части, чтобы получить только NTIN
                                              const [ntinCode, ...nameParts] =
                                                option.label.split(" ");

                                              return {
                                                value: option.value, // Полное значение
                                                label: ntinCode, // Отображаем только NTIN-код
                                              };
                                            }) || []
                                        }
                                        onChange={(e: any) => {
                                          console.log(e, "eee");

                                          const previousValue =
                                            formik.values.orderProducts[index]
                                              ?.ntin;
                                          handleProductChange(
                                            index,
                                            "ntin",
                                            e,
                                            formik,
                                          );

                                          const selectedOptionData =
                                            ntinTypeCode[index].find(
                                              (item: any) => item.value === e,
                                            );

                                          if (selectedOptionData) {
                                            console.log(
                                              "selectedOptionData",
                                              selectedOptionData.label
                                                .split(" ")
                                                .slice(1)
                                                .join(" "),
                                            );

                                            formik.setFieldValue(
                                              `orderProducts[${index}].name`,
                                              selectedOptionData.label
                                                .split(" ")
                                                .slice(1)
                                                .join(" "),
                                            );
                                            handleProductChange(
                                              index,
                                              "name",
                                              selectedOptionData.label
                                                .split(" ")
                                                .slice(1)
                                                .join(" "),
                                              formik,
                                            );
                                          } else {
                                            formik.setFieldValue(
                                              `orderProducts[${index}].name`,
                                              "",
                                            );
                                          }

                                          // Обновляем ntinCodeObject
                                          setNtinCodeObject((prev) => {
                                            const updatedObject = { ...prev };

                                            // Удаляем предыдущее значение, если оно существовало
                                            if (
                                              previousValue &&
                                              updatedObject[previousValue]
                                            ) {
                                              delete updatedObject[
                                                previousValue
                                              ];
                                            }

                                            // Добавляем новое значение
                                            updatedObject[e] = true;

                                            return updatedObject;
                                          });
                                          // Вызов handleChange для других обработчиков
                                          formik.handleChange(e);

                                          // Установка флага изменения формы
                                          dispatch(setBlocker(true))
                                          setIsFormChanged(true);
                                        }}
                                        // isDisabled={
                                        //   (formik.values.status !== "new" &&
                                        //     formik.values.status !== "DRAFT") ||
                                        //   !formik.values.orderProducts[index].productTypeCode
                                        // }
                                        isDisabled={
                                          (formik.values.status !== "new" &&
                                            formik.values.status !== "DRAFT") ||
                                          !formik.values.orderProducts[index]
                                            .productTypeCode ||
                                          formik.values.orderProducts[index]
                                            ?.marksAmount > 0 || !role.roleDraft.edit
                                        }
                                        isError={
                                          getIn(
                                            formik.touched,
                                            `orderProducts[${index}].ntin`,
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `orderProducts[${index}].ntin`,
                                          )
                                        }
                                        withoutStyle={true}
                                        noOptionsMessage={noOptionsMessage}
                                      />
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] py-0 ">
                                    <div>
                                      <Field
                                        disabled={true}
                                        type="text"
                                        name={`orderProducts[${index}].markCode`}
                                        value={
                                          formik.values.orderProducts[index]?.markCode || ""
                                        }
                                        onChange={(e: any) => {
                                          const value = e.target.value;
                                          if (
                                            value === "" ||
                                            /^\d*\.?\d{0,3}$/.test(value)
                                          ) {
                                            formik.handleChange(e);
                                            handleProductChange(
                                              index,
                                              "code",
                                              e.target.value,
                                              formik,
                                            );
                                          }
                                          dispatch(setBlocker(true))
                                          setIsFormChanged(true);
                                        }}
                                        placeholder={t(
                                          "documentLabels.choose_option",
                                        )}
                                        className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                      />
                                    </div>
                                  </td>
                                  <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] w-[270--px] py-0 ">
                                    <div className={`${style.inputField3}`}>
                                      <Field
                                        disabled={true}
                                        type="text"
                                        name={`orderProducts[${index}].productTypeCode`}
                                        value={
                                          formik.values.orderProducts[index]
                                            ?.productTypeCode || ""
                                        }
                                        placeholder={t(
                                          "documentLabels.choose_option",
                                        )}
                                        className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                      />
                                    </div>
                                  </td>

                                </>
                              )}
                              {dowloadFromDock ? (
                                <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center box-content w-[280px]">
                                  {/* {value?.unitsNumber || "-"} */}
                                  <div className={`${style.inputField3}`}>
                                    <Field
                                      disabled={true}
                                      type="text"
                                      name={`orderProducts[${index}].ntin`}
                                      value={
                                        formik.values.orderProducts[index]
                                          ?.ntin
                                      }
                                      placeholder={t(
                                        "documentLabels.choose_option",
                                      )}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] py-0 "
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    formik.values.orderProducts[index]?.name
                                  }
                                >
                                  {/* {value?.weight || "-"} */}
                                  <div>
                                    <Field
                                      disabled={true}
                                      type="text"
                                      name={`orderProducts[${index}].name`}
                                      value={
                                        formik.values.orderProducts[index]?.name
                                      }
                                      placeholder={t(
                                        "Выберите NTIN",
                                      )}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        if (
                                          value === "" ||
                                          /^\d*\.?\d{0,3}$/.test(value)
                                        ) {
                                          console.log("changing");
                                          formik.handleChange(e);
                                          handleProductChange(
                                            index,
                                            "name",
                                            e.target.value,
                                            formik,
                                          );
                                        }
                                        dispatch(setBlocker(true))
                                        setIsFormChanged(true);
                                      }}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    />
                                  </div>
                                  <Tooltip id="my-tooltip" place="top" delayHide={1500} />
                                </td>
                              )}
                              {/* ТАК ТУТ НАИМЕНОВАНИЕ ИЛИ ВЕС */}
                              {dowloadFromDock ? (
                                <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] py-0 ">
                                  {/* {value?.weight || "-"} */}
                                  <div>
                                    <Field
                                      disabled={true}
                                      type="number"
                                      name={`orderProducts[${index}].weight`}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        if (
                                          value === "" ||
                                          /^\d*\.?\d{0,3}$/.test(value)
                                        ) {
                                          formik.handleChange(e);
                                          handleProductChange(
                                            index,
                                            "weight",
                                            e.target.value,
                                            formik,
                                          );
                                        }
                                        dispatch(setBlocker(true))
                                        setIsFormChanged(true);
                                      }}
                                      placeholder={t(
                                        "documentLabels.choose_option",
                                      )}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className=" text-sm bg-white border-b text-gray-600 text-center min-w-[200px] py-0 ">
                                  {/* {value?.weight || "-"} */}
                                  <div>
                                    <Field
                                      disabled={true}
                                      type="text"
                                      name={`orderProducts[${index}].marksAmount`}
                                      value={
                                        formik.values.orderProducts[index]
                                          ?.marksAmount
                                      }
                                      placeholder={t(
                                        "Выберите NTIN",
                                      )}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    />
                                  </div>
                                </td>
                              )}

                              {/* ТАК ТУТ КОЛИЧЕСТВО МАРОК ИЛИ РАЗМЕР */}
                              {dowloadFromDock ? (
                                <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                  {/* {value?.size || "-"} */}
                                  <div>
                                    <Field
                                      disabled={
                                        formik.values.status !== "new"
                                      }
                                      type="number"
                                      name={`orderProducts[${index}].size`}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        // Разрешаем вводить только числа и точку, ограничиваем до 3 знаков после запятой
                                        if (
                                          value === "" ||
                                          /^\d*\.?\d{0,3}$/.test(value)
                                        ) {
                                          formik.setFieldValue(
                                            `orderProducts[${index}].size`,
                                            value,
                                          );
                                          handleProductChange(
                                            index,
                                            "size",
                                            value,
                                            formik,
                                          );
                                        }
                                        dispatch(setBlocker(true))
                                        setIsFormChanged(true);
                                      }}
                                      placeholder={t(
                                        "documentLabels.choose_option",
                                      )}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center">
                                  {/* {value?.size || "-"} */}
                                  <div>
                                    {/* <Field
                                      disabled={true}
                                      type="number"
                                      name={`orderProducts[${index}].size`}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        // Разрешаем вводить только числа и точку, ограничиваем до 3 знаков после запятой
                                        if (
                                          value === "" ||
                                          /^\d*\.?\d{0,3}$/.test(value)
                                        ) {
                                          formik.setFieldValue(
                                            `orderProducts[${index}].size`,
                                            value,
                                          );
                                          handleProductChange(
                                            index,
                                            "size",
                                            value,
                                            formik,
                                          );
                                        }
                                        setIsFormChanged(true);
                                      }}
                                      placeholder={t(
                                        "documentLabels.choose_option",
                                      )}
                                      className={`${"border-gray-300 text-black placeholder-gray-400 text-center p-[20px]"} ${style.placeholder}`}
                                    /> */}
                                  </div>
                                </td>
                              )}


                              {!dowloadFromDock && (
                                <td className="px-5 text-sm bg-white border-b border-gray-200 text-gray-600 text-center box-content w-[150px] ">
                                {isProductTypeCodeSelected && isNTINSelected && (
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      console.log(isSubmitting);
                                      const errors = await formik.validateForm();

                                      if (Object.keys(errors).length > 0) {
                                        const touchedFields: any = {};
                                      
                                        Object.keys(errors).forEach((key) => {
                                          const errorValue = errors[key];
                                      
                                          // Проверяем, является ли errorValue массивом (для orderProducts)
                                          if (Array.isArray(errorValue)) {
                                            (errorValue as Array<FormikErrors<any>>).forEach((nestedError, index) => {
                                              if (nestedError && typeof nestedError === 'object') {
                                                touchedFields[key] = touchedFields[key] || [];
                                                touchedFields[key][index] = {};
                                      
                                                // Помечаем все поля внутри объекта с ошибками как touched
                                                Object.keys(nestedError).forEach((nestedKey) => {
                                                  touchedFields[key][index][nestedKey] = true;
                                                });
                                              }
                                            });
                                          } else {
                                            touchedFields[key] = true;
                                          }
                                        });
                                      
                                        formik.setTouched(touchedFields);
                                        console.log('Touched fields:', touchedFields);
                                        return;
                                      }
                                      if (!isLoading) {
                                        setIsLoading(true); // Блокируем повторные клики
                                        try {
                                          dispatch(clearBlocker())
                                          setIsFormChanged(false);
                                          await submitCreate(
                                            formik.values,
                                            formik.values.orderProducts[index].ntin
                                          );
                                        } catch (error) {
                                          console.log(error);
                                          setIsLoading(false)
                                          
                                        } 
                                      }
                                    }}
                                    className={`bg-green text-white px-3 py-1 rounded h-[45px] w-full ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isLoading} // Отключаем кнопку, если отправка в процессе
                                  >
                                    {formik.values.orderProducts[index]?.marksAmount > 0
                                      ? t("productPersonalization.view")
                                      : t("productPersonalization.add_codes")}
                                  </button>
                                )}
                              </td>
                              )}

                              {formik.values.status !== "new" &&
                                formik.values.status !== "DRAFT" ? (
                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600"></td>
                              ) : (
                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                  <img
                                    src="/icons/trash.svg"
                                    className="cursor-pointer min-w-[20px]"
                                    onClick={() => {
                                      handleRemove(
                                        value.atributId,
                                        value.id,
                                        index,
                                        formik,
                                      );
                                      dispatch(setBlocker(true))
                                      setIsFormChanged(true);
                                      console.log(
                                        ntinCodeObject,
                                        "ntinCodeObject",
                                      );
                                    }}
                                  ></img>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6} className="text-center py-5">
                          <div>
                            <EmptyListPlaceholderContent
                              title={t("documents_upload.noData")}
                            // description={"Добавьте изделие в таблицу"}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <div className="m-[10px] flex justify-between">
                  <div className="flex gap-[20px] justify-center">
                    {(formik.values.status === "new" ||
                      formik.values.status === "DRAFT") &&
                      (dowloadFromDock ? (
                        <>
                          <button
                            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                            type="button"
                            onClick={() => {
                              submitCreate(formik.values);
                              setIsFileUploadModalOpen(true);

                            }}
                          >
                            {t("new_order.dowload_product_button")}
                          </button>
                          <button
                            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                            type="button"
                            onClick={() => {
                              window.location.href = `/template.xlsx`;
                            }}
                          >
                            {t("new_order.dowload_shablon_button")}
                          </button>
                        </>
                      ) : (
                        <div className="flex gap-[20px]">
                          {role.roleDraft.edit && (
                            <button
                              className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                              type="button"
                              onClick={() => {
                                addNewBlock(formik);
                                dispatch(setBlocker(true))
                                setIsFormChanged(true);
                              }}
                            >
                              {t("new_order.add_product_button")}
                            </button>
                          )}

                          <div className="text-red-500">
                            {/* {Array.isArray(formik.errors.orderProducts) &&
            formik.errors.orderProducts[0] &&
            typeof formik.errors.orderProducts[0] !== 'string' &&
            typeof formik.errors.orderProducts[0].productTypeCode === 'string' && (
              <div className="error-container">
                {formik.errors.orderProducts[0].productTypeCode}
              </div>
            )} */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {formik.values.orderProducts?.length > 0 && (
                <Pagination
                  totalItems={formik.values.orderProducts?.length}
                  totalPages={Math.ceil(
                    formik.values.orderProducts?.length / pagination.pageSize,
                  )}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              )}
            </div>
            {isLoading && <Loader />}
            {isBlocked && blocker.state === "blocked" ? (
            <ModalComponent
              isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
              onClose={() => {
                if (blocker && typeof blocker.reset === "function") {
                  blocker.reset();
                } else {
                  console.error("Reset function is not available.");
                }
              }}
              headerContent={
                <>
                  <span>{t("documentNotification.notification")}</span>
                  <button
                    type="button"
                    onClick={() => {
                      if (blocker && typeof blocker.reset === "function") {
                        blocker.reset();
                      } else {
                        console.error("Reset function is not available.");
                      }
                    }}
                    aria-label="Close"
                    className="float-right cursor-pointer bg-none border-none"
                  >
                    &times; {/* Символ крестика */}
                  </button>
                </>
              }
              content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
              customContent={
                <>
                 <ModalButton
        type="button"
        onClick={() => {
          dispatch(clearBlocker())
          if (blocker && typeof blocker.proceed === "function") {
            blocker.proceed(); // Уходим со страницы без сохранения
          } else {
            console.error("Proceed function is not available.");
          }
        }}
      >
        {t("mainButtons.cancel")}
      </ModalButton>
                  <ModalButton
                    type="button"
                    color="green"
                    onClick={async () => {
                      dispatch(clearBlocker())
                      try {
                        await submitCreate(formik.values); // Сохранение данных
                        if (blocker && typeof blocker.proceed === "function") {
                          blocker.proceed(); // Переход после сохранения
                        }
                      } catch (error) {
                        console.error("Error saving data:", error);
                        // Можно обработать ошибку, например, показать уведомление пользователю
                      }
                    }}
                  >
                    {t("mainButtons.continue")}
                  </ModalButton>
                </>
              }
            />
            
            ) : null}
            <CheckResult
              isOpen={isCheckOpen}
              setIsOpen={setIsCheckOpen}
              problemMarks={isCheckOpen}
              docId={ProductPersonalizationId}
              personalization={fullPersonalizationPromlem}
              onLoad={onLoad}
            />
            <CheckResultPersonalization
              isOpen={fullFieldPromlem}
              setIsOpen={setFullFieldPromlem}
              problemMarks={fullFieldPromlem}
              docId={ProductPersonalizationId}
              personalization={fullFieldPromlem}
              onLoad={onLoad}
            />
            <PersonalizationUpload
              isOpen={isFileUploadModalOpen}
              setIsOpen={setIsFileUploadModalOpen}
              documentId={ProductPersonalizationId}
              toggleOnLoad={toggleOnLoad}
              setToggleOnLoad={setToggleOnLoad}
              createDoc={() => submitCreate(formik.values)}
              onLoad={onLoad}
            />
            <ModalComponent
              isOpen={errorModal}
              onClose={() => setErrorModal(null)}
              headerContent={
                <h1 className="h__modal">
                  {t("documentNotification.notification")} -{" "}
                  {t("documentNotification.check_fields")}:
                </h1>
              }
              content={
                <div className="error-list">
                  <ul>
                    {errorModal && errorModal?.length > 0 ? (
                      errorModal.map((error: any, index: any) => (
                        <li
                          className="border-b border-gray-300 py-1"
                          key={index}
                        >
                          {t(`error_dictionary.${error}`)}
                        </li>
                      ))
                    ) : (
                      <li>{t("documentNotification.no_errors")}</li>
                    )}
                  </ul>
                </div>
              }
              customContent={
                <ModalButton
                  type="button"
                  onClick={() => {
                    setErrorModal(null);
                  }}
                >
                  {t("mainButtons.accept")}
                </ModalButton>
              }
            />
            <AlertBox
              isOpen={alertOpen}
              setIsOpen={setAlertOpen}
            />
            <ModalComponent
              isOpen={errorModal?.singleError}
              onClose={() => setErrorModal(null)}
              headerContent={
                <h1 className="h__modal">
                  {" "}
                  {t("documentNotification.notification")}
                </h1>
              }
              content={
                <div className="error-list">
                  <p className="border-b border-gray-300 py-1">
                    {t("documentStatus.error")}: {errorModal?.errorMessage}
                  </p>
                </div>
              }
              customContent={
                <ModalButton
                  type="button"
                  onClick={() => {
                    setErrorModal(null);
                  }}
                >
                  {t("mainButtons.accept")}
                </ModalButton>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProductPersonalization;
