import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import { searchByIin } from "../../../common/api/authApi";
import DocumentsService from "../../../common/api/documentsApi";
import { createDocument } from "../../../common/api/notificationApi";
import { GetReferenceService } from "../../../common/api/ordersApi";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import CalendarSelect from "../../../shared/Ui/Calendar/CalendarSelect";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import Stepper from "../../../shared/components/Stepper/Stepper";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import {
  deleteDataDocument,
  saveDataDocument,
} from "../../../shared/slice/documentState";
import { generalDocumentInfo } from "../types";
import style from "./DocumentInventoryResumption.module.css";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { FaExclamationCircle } from "react-icons/fa";
import Loader from "../../../shared/Ui/Loader/Loader";
import CalendarSelectFormik from "../../../shared/Ui/Calendar/ CalendarSelectFormik";

interface IInitialValues extends generalDocumentInfo {
  orgId: string; // +
  createUser: string; // +
  createdAt: string; // +
  updatedAt?: string;
  signingAt?: string;
  receiver: string; // +
  sender: string; // +
  status: string; // +

  refCountryExport: string;
  documentPaperNumber?: string; // Hoмер бум. документа
  documentPaperAt?: string; //Дата бум. документа
  document_id: string; // ИД документа
  customsClearanceRegNumber: string;
}

function DocumentInventoryResumption() {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const dispatch = useAppDispatch();
  const documentState = useAppSelector((state) => state.documentState);
  const navigate = useNavigate();
  const [putReasonsList, setPutReasonsList] = useState([]);
  const { inventoryResumptionDocumentId } = useParams();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const userData = useAppSelector((state) => state.auth.userData);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [organizationName, setOrganizationName] = useState({
    nameRu: "",
    nameKk: "",
  });
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const [initialValues, setInitialValues] = useState<any>({
    id: null, // ИД
    refDocType: 3,

    refPutReason: null, //Причина вывода из оборота
    foundationDocumentName: null,
    foundationDocumentNumber: null,
    foundationDocumentDate: null,
    note: "", // Примечание

    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",
    receiver: "",
    sender: "",
    status: "new",
  });

  const formValidationSchema = Yup.object().shape({
    refPutReason: Yup.string().required(t("documentsError.reason_of_input")),
    foundationDocumentName: Yup.string()
      .required(t("documentsError.required"))
      .min(3, t("documentsError.minCharacters", { count:3 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    foundationDocumentNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    foundationDocumentDate: Yup.string()
      .required(t("documentsError.required"))
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(256, t("documentsError.maxCharacters", { count: 256 })),
    note: Yup.string()
      .nullable()
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(256, t("documentsError.maxCharacters", { count: 256 })),
  });

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: "documentStatus.error" };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  function onLoad() {
    if (!inventoryResumptionDocumentId) return Promise.resolve(); // Если ID не определён, возвращаем разрешённый промис

    setLoading(true); // Начало загрузки данных

    return DocumentsService.getDocument(inventoryResumptionDocumentId)
      .then((documentResponse) => {
        if (documentResponse && documentResponse?.data) {
          console.log(
            "Загрузка информации о документе о ввозе товаров из третьих стран:",
            documentResponse,
          );
          setInitialValues(documentResponse?.data);

          return searchByIin(documentResponse?.data?.receiver)
            .then((organizationNameResponse) => {
              setOrganizationName({
                nameRu: organizationNameResponse?.data[0]?.nameRu,
                nameKk: organizationNameResponse?.data[0]?.nameKk,
              });
            })
            .catch((error) => {
              console.error("Ошибка при загрузке данных о получателе:", error);
            });
        } else {
          console.log("Документ не найден или данные недоступны.");
        }
      })
      .catch((error) => {
        console.error(
          "Ошибка при загрузке информации о документе о ввозе товаров из третьих стран:",
          error,
        );
        navigate("/404");
      })
      .finally(() => {
        setLoading(false); // Завершение загрузки данных
      });
  }

  useEffect(() => {
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
          .specificAction.documents[0],
        roleDraft: roleMatrix?.roleMatrix?.find((item) => item.id === 6),
        roleAccepted: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
      };
    };

    setRole(roleObj);

    if (inventoryResumptionDocumentId !== undefined) {
      onLoad().then(() => {
        if (documentState.isSafe) {
          dispatch(setBlocker(true))
          setIsFormChanged(true);
          setInitialValues((prev: any) => {
            console.log(prev);
            return { ...prev, ...documentState.documentData };
          });
        }
      });
    } else {
      setLoading(false);
    }
    if (!inventoryResumptionDocumentId && documentState.isSafe) {
      dispatch(setBlocker(true))
      setIsFormChanged(true);
      setInitialValues((prev: any) => {
        return { ...prev, ...documentState.documentData };
      });
    }
    GetReferenceService("document/put-in-circulation-reasons/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setPutReasonsList(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
  }, [inventoryResumptionDocumentId]);

  async function submitCreate(formikValues: any) {
    const backendData = {
      note: formikValues.note || null, // Примечание
      refPutReason: formikValues.refPutReason || null, //Причина вывода из оборота
      foundationDocumentName: formikValues.foundationDocumentName || null,
      foundationDocumentNumber: formikValues.foundationDocumentNumber || null,
      foundationDocumentDate: formikValues.foundationDocumentDate || null,

      refDocType: 3,
      id: inventoryResumptionDocumentId || null,
    };

    if (inventoryResumptionDocumentId !== undefined) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    } else {
      await createDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          navigate(
            `/documents/inventory-resumption/${response.data.document.id}`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена

      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("inventory-resumption"),
  );

  if (!role || !role?.roleAccepted?.view) {
    return <PageNotRole />;
  }

  if (loading && !role) return <Loader />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues) => submitCreate(formikValues)}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={formValidationSchema}
    >
      {(formik) => (
        <Form className="max-w-[1585px] w-full m-t-[20px]">
         {isBlocked && blocker.state === "blocked" ? (
            <ModalComponent
            isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
            onClose={() => {
              if (blocker && typeof blocker.reset === "function") {
                blocker.reset();
              } else {
                console.error("Reset function is not available.");
              }
            }}
            headerContent={
              <>
                <span>{t("documentNotification.notification")}</span>
                <button
                  type="button"
                  onClick={() => {
                    
                    if (blocker && typeof blocker.reset === "function") {
                      blocker.reset();
                    } else {
                      console.error("Reset function is not available.");
                    }
                  }}
                  aria-label="Close"
                  className="float-right cursor-pointer bg-none border-none"
                >
                  &times; {/* Символ крестика */}
                </button>
              </>
            }
            content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
            customContent={
              <>
               <ModalButton
      type="button"
      onClick={() => {
        dispatch(clearBlocker())
        if (blocker && typeof blocker.proceed === "function") {
          blocker.proceed(); // Уходим со страницы без сохранения
        } else {
          console.error("Proceed function is not available.");
        }
      }}
    >
      {t("mainButtons.cancel")}
    </ModalButton>
                <ModalButton
                  type="button"
                  color="green"
                  onClick={async () => {
                    dispatch(clearBlocker())
                    try {
                      await submitCreate(formik.values); // Сохранение данных
                      if (blocker && typeof blocker.proceed === "function") {
                        blocker.proceed(); // Переход после сохранения
                      }
                    } catch (error) {
                      console.error("Error saving data:", error);
                      // Можно обработать ошибку, например, показать уведомление пользователю
                    }
                  }}
                >
                  {t("mainButtons.save")}
                </ModalButton>
              </>
            }
          />
          ) : null}
          <div className="flex flex-col gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <div>
                {inventoryResumptionDocumentId ? (
                  <div className="flex gap-[20px]">
                    <div className="flex flex-col">
                      <StatusBadge type={statusType.type}>
                        {t("documentLabels.status") + ": " + statusType.label}
                      </StatusBadge>
                      {initialValues?.createdAt && (
                        <div className="text-[14px] font-semibold text-gray-700">
                          {format(
                            new Date(initialValues?.createdAt),
                            "dd.MM.yyyy, HH:mm",
                          )}
                        </div>
                      )}
                    </div>
                    <span className="flex gap-[10px]">
                      <div>{getDescription(formik.values.refDocType)}:</div>
                      <div>
                        {formik.values.docNumber
                          ? formik.values.docNumber
                          : " " + t("mainLabels.not_generated")}
                      </div>
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-[20px]">
                    <StatusBadge type={statusType.type}>
                      {t("documentLabels.status") + " " + statusType.label}
                    </StatusBadge>
                    <div>
                      {t("documentLabels.create_document")}
                      {" :"}
                      {t("documentType.re_introduction_notification")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between ">
              <div className="my-auto">
                <Stepper />
              </div>

              <div className="flex flex-row gap-[20px] flex-wrap">
                {!loading && (
                  <>
                    <button
                      className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        dispatch(deleteDataDocument());
                        if (initialValues.status == "DRAFT") {
                          console.log("Navigating to drafts");
                          navigate("/documents/drafts");
                        } else if (initialValues.status == "new") {
                          console.log("Navigating to sent");
                          navigate("/documents/inbox");
                        } else if (userData.iinBin !== initialValues.receiver) {
                          console.log("Navigating to sent");
                          navigate("/documents/sent");
                        } else {
                          console.log("Navigating to inbox");
                          navigate("/documents/inbox");
                        }
                      }}
                    >
                      {t("mainButtons.close")}
                    </button>

                    {((formik.values.status === "new" && role.roleDraft.edit) ||
                      (formik.values.status == "DRAFT" &&
                        role.roleDraft.edit)) && (
                      <div className="flex flex-row gap-[20px] flex-wrap">
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                          type="submit"
                        >
                          {t("documentLabels.check_completion")}
                        </button>
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                          type="button"
                          onClick={() => {
                            dispatch(deleteDataDocument());
                            submitCreate(formik.values);
                          }}
                        >
                          {t("mainButtons.save")}
                        </button>
                      </div>
                    )}
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        if (isFormChanged) {
                          dispatch(saveDataDocument(formik.values));
                        }
                        navigate("orders");
                      }}
                    >
                      {t("mainButtons.next")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px] pt-[20px]">
              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <span className={style.labelSpan}>
                      {t("documentLabels.iinBin") +
                        ", " +
                        t("documentLabels.organization_name_receiver")}
                    </span>
                  </div>
                  <span className={style.spanValue}>
                    {inventoryResumptionDocumentId
                      ? formik?.values?.receiver
                      : userData?.iinBin || "-"}
                    ,{" "}
                    {inventoryResumptionDocumentId
                      ? locale === "ru"
                        ? organizationName?.nameRu
                        : locale === "kk"
                          ? organizationName?.nameKk
                          : "-"
                      : userData?.organizationName || "-"}
                  </span>
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap ">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("re_introduction_notification.put_reason")} *
                    </label>
                    {/* {formik.touched.refPutReason &&
                      formik.errors.refPutReason && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.refPutReason === "string"
                            ? formik.errors.refPutReason
                            : t(
                                "re_introduction_notification.put_reason_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.refPutReason &&
                      formik.errors.refPutReason && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.refPutReason === "string"
                              ? formik.errors.refPutReason
                              : t(
                                  "re_introduction_notification.put_reason_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    value={formik.values.refPutReason}
                    component={SelectCustomFormik}
                    name="refPutReason"
                    placeholder={t("documentLabels.choose")}
                    size="sm"
                    isMulti={false}
                    options={putReasonsList}
                    isDisabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(selectedOption: any) => {
                      const newValue = selectedOption
                        ? selectedOption.value
                        : "";
                        dispatch(setBlocker(true))
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("re_introduction_notification.document_name")} *
                    </label>
                    {/* {formik.touched.foundationDocumentName &&
                      formik.errors.foundationDocumentName && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.foundationDocumentName ===
                          "string"
                            ? formik.errors.foundationDocumentName
                            : t(
                                "re_introduction_notification.document_name_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.foundationDocumentName &&
                      formik.errors.foundationDocumentName && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.foundationDocumentName ===
                            "string"
                              ? formik.errors.foundationDocumentName
                              : t(
                                  "re_introduction_notification.document_name_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="foundationDocumentName"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.foundationDocumentName}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(setBlocker(true))
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("re_introduction_notification.document_number")} *
                    </label>
                    {/* {formik.touched.foundationDocumentNumber &&
                      formik.errors.foundationDocumentNumber && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.foundationDocumentNumber ===
                          "string"
                            ? formik.errors.foundationDocumentNumber
                            : t(
                                "re_introduction_notification.document_number_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.foundationDocumentNumber &&
                      formik.errors.foundationDocumentNumber && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.foundationDocumentNumber ===
                            "string"
                              ? formik.errors.foundationDocumentNumber
                              : t(
                                  "re_introduction_notification.document_number_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="foundationDocumentNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.foundationDocumentNumber}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(setBlocker(true))
                      setIsFormChanged(true);
                    }}
                  />
                </div>

                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("re_introduction_notification.document_date")}*
                    </label>
                    {/* {formik.touched.foundationDocumentDate &&
                      formik.errors.foundationDocumentDate && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.foundationDocumentDate ===
                          "string"
                            ? formik.errors.foundationDocumentDate
                            : t(
                                "re_introduction_notification.document_date_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.foundationDocumentDate &&
                      formik.errors.foundationDocumentDate && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.foundationDocumentDate ===
                            "string"
                              ? formik.errors.foundationDocumentDate
                              : t(
                                  "re_introduction_notification.document_date_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <CalendarSelectFormik
                  documentStatus={initialValues.status}
                    name="foundationDocumentDate"
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={() =>{
                      dispatch(setBlocker(true))
                       setIsFormChanged(true)
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap max-w-[97%]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("re_introduction_notification.note")}
                    </label>
                    {formik.touched.note && formik.errors.note && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.note === "string"
                            ? formik.errors.note
                            : t(
                                "re_introduction_notification.document_date_error",
                              )}
                        </div>
                      </div>
                    )}
                  </div>
                  <Field
                    as="textarea"
                    name="note"
                    placeholder={t("documentLabels.choose_option")}
                    className={`${style.textareaPlaceholder} textarea-placeholder w-full h-[45px] min-h-[45px] border border-[#a3c0d2] rounded-lg px-4 pt-3 text-sm text-[#2e3138] font-medium leading-5 outline-none`}
                    value={formik.values.note}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(setBlocker(true))
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        </Form>
      )}
    </Formik>
  );
}

export default DocumentInventoryResumption;
