import { PaginationState } from "@tanstack/react-table";
import { format } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentsService from "../../../../common/api/documentsApi";
import { getOrdersList } from "../../../../common/api/ordersApi";
import CheckBox from "../../../../shared/Ui/CheckBox/CheckBox";
import { EmptyListPlaceholderContent } from "../../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../../../shared/components/Table/Pagination";
import { ITable } from "../../../../shared/components/Table/types";
import { useAppSelector } from "../../../../shared/hook";
import { generateAndDownloadExcel } from "../../../../shared/hooks/generateFiles";
import style from "./DocumentUpload.module.css";
import FileUpload from "../../../../shared/components/FileUpload/FileUpload";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import { addMarksByFile } from "../../../../common/api/nktApi";
import AlertBox from "../../../../shared/Ui/AlertBox/AlertBox";
import * as XLSX from 'xlsx';

export interface IDocumentUpload {
  documentId: string | undefined;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleOnLoad: boolean;
  setToggleOnLoad: Dispatch<SetStateAction<boolean>>;
  personalization?: any;
} 

export const DocumentUpload: React.FC<IDocumentUpload> = (props) => {
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [resultsTableData, setResultsTableData] = useState<ITable>();
  const [resultsPagination, setResultsPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [errorModal, setErrorModal] = useState<any>(null);

  const onModalOpen = () => props.setIsOpen(true);
  const onModalClose = () => {
    setUploadedFiles([])
  props.setIsOpen(false)
  };

  const [resultsModalOpen, setResultsModalOpen] = useState<boolean>(false);
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);

  const onResultsModalOpen = () => setResultsModalOpen(true);
  const onResultsModalClose = () => setResultsModalOpen(false);
  const countExcelFileLines = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Выбираем первый лист
        const worksheet = workbook.Sheets[sheetName];
  
        // Преобразуем лист в массив массивов
        const jsonSheet: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
        // Считаем количество непустых строк
        const lineCount = jsonSheet.filter((row: any[]) => row.some((cell) => cell !== null && cell !== '')).length;
  
        resolve(lineCount);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file); // Читаем файл как ArrayBuffer
    });
  };
  

  // async function onLoadResults() {
  //   setIsResultsLoading(true);
 
  //   if (props.documentId) {
  //     const request = props.personalization 
  //       ? addMarksByFile(props.personalization.documentId, props.personalization.productId, uploadedFiles[0])
  //       : DocumentsService.addMarksByFile(props.documentId, uploadedFiles[0])
  
  //     await request
  //       .then((response:any) => {
  //         console.log(response);
  //         if (response?.problemMarks?.length === 0) {
  //           setIsResultsLoading(false);
  //           onResultsModalClose();
  //           setAlertOpen({
  //             status: `${response?.total > 0 ? 'SUCCESS':'error'}`,
  //             message: `Успешно добавлено марок: ${response?.total}`,
  //           });
  //         } else {
  //           onResultsModalOpen();
  //           setResultsTableData({
  //             values: response?.problemMarks,
  //             currentPage: 0,
  //             itemsPerPage: resultsPagination.pageSize,
  //             totalItems: response?.total,
  //             totalPages: 1,
  //           });
  //           setAlertOpen({
  //             status: `${response?.total > 0 ? 'SUCCESS':'error'}`,
  //             message: `Успешно добавлено марок: ${response?.total}`,
  //           });
  //         }
  //       })
  //       .catch((error:any) =>
  //         setErrorModal(
  //           `${error?.response?.data?.message || t("documents_file_upload.error_file_format")}`,
  //         ),
  //       )
  //       .finally(() => {
  //         setAlertOpen({
  //           status: "SUCCESS",
  //           message: t("documentNotification.document_edited_success"),
  //         });
  //         setIsResultsLoading(false);
  //         props.setToggleOnLoad(!props.toggleOnLoad);
  //         if (resultsTableData?.values?.length === 0) {
  //           onResultsModalClose();
  //         }
  //       })};
  // }
  async function onLoadResults() {
    setIsResultsLoading(true);
  
    if (props.documentId) {
      const request = props.personalization 
        ? addMarksByFile(props.personalization.documentId, props.personalization.productId, uploadedFiles[0])
        : DocumentsService.addMarksByFile(props.documentId, uploadedFiles[0]);
  
      try {
        const response = await request;
        
        // Подсчет строк в файле
        const lineCount = await countExcelFileLines(uploadedFiles[0]);
        console.log(1,lineCount);
        console.log(2,response?.total);
        
        console.log('lineCount',lineCount-response?.total);
        
        // const totalMarks = v
  
        if (response?.problemMarks?.length === 0) {
          setIsResultsLoading(false);
          onResultsModalClose();
          setAlertOpen({
            status: `${lineCount-response?.total > 0 ? 'SUCCESS' : 'error'}`,
            message: `Успешно добавлено марок:${lineCount-response?.total}`,
          });
        } else {
          onResultsModalOpen();
          setResultsTableData({
            values: response?.problemMarks,
            currentPage: 0,
            itemsPerPage: resultsPagination.pageSize,
            totalItems: response?.total,
            totalPages: 1,
          });
          setAlertOpen({
            status: `${lineCount-response?.total > 0 ? 'SUCCESS' : 'error'}`,
            message: `Успешно добавлено марок:${lineCount-response?.total}`,
          });
        }
      } catch (error:any) {
        setErrorModal(
          `${error?.response?.data?.message || t("documents_file_upload.error_file_format")}`,
        );
      } finally {
        // setAlertOpen({
        //   status: "SUCCESS",
        //   message: t("documentNotification.document_edited_success"),
        // });
        setIsResultsLoading(false);
        props.setToggleOnLoad(!props.toggleOnLoad);
        if (resultsTableData?.values?.length === 0) {
          onResultsModalClose();
        }
      }
    }
  }

  const renderFileUpload = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <span>{t("documents_file_upload.file_upload_title")}: </span>
        </div>
        <div className="text-gray-500 font-semibold text-base">
          <p>{t("documents_file_upload.file_upload_requirements")} </p>
          <ul className="flex flex-col gap-[6px] my-6">
            <li>{t("documents_file_upload.file_type_requirement")};</li>
            <li>{t("documents_file_upload.file_single_sheet")};</li>
            <li>{t("documents_file_upload.file_code_column")};</li>
            <li>{t("documents_file_upload.file_non_empty")};</li>
            <li>{t("documents_file_upload.file_text_cells")}.</li>
          </ul>
        </div>
        <div>
          <FileUpload
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
          />
        </div>
      </div>
    );
  };

  function getMarkStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "ISSUED":
        return {
          type: "ISSUED",
          label: t("documents_file_upload.code_status_issued"),
        };
      case "INFLICTED":
        return {
          type: "INFLICTED",
          label: t("documents_file_upload.code_status_inflicted"),
        };
      case "IN_CIRCULATION":
        return {
          type: "IN_CIRCULATION",
          label: t("documents_file_upload.code_status_in_circulation"),
        };
      case "WITHDRAWN_FROM_CIRCULATION":
        return {
          type: "WITHDRAWN_FROM_CIRCULATION",
          label: t("documents_file_upload.code_status_withdrawn"),
        };
      default:
        return { type: "", label: "-" };
    }
  }

  const generateExcelValues = (data: any) => {
    type TransformedObject = {
      [key: string]: any;
    };

    const results =
      data &&
      data?.map((item: any, index: number) => {
        const newObj: TransformedObject = {};

        newObj[`№ ${t("documents_upload.serialNumber")}`] = index + 1;

        for (const [key, value] of Object.entries(item)) {
          if (key === "code") {
            const newKey = t("documents_file_upload.code");
            newObj[newKey] = value;
          }

          if (key === "status") {
            const newKey = t("documents_file_upload.code_status");
            newObj[newKey] = getMarkStatusType(item?.status)?.label || "-";
          }

          if (key === "reason") {
            const newKey = t("documents_upload.checkStatus");
            newObj[newKey] = value;
          }
        }

        return newObj;
      });

    return generateAndDownloadExcel(
      results,
      // `orders-check-${props.documentId}`,
      `${t('CheckResult.FileName')}`,
    );
  };

  const renderResultsTable = () => {
    return (
      <>
        {isResultsLoading ? (
          <div className="w-full text-center flex flex-col justify-center items-center gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.pleaseWaitMessage")}!</span>
              <span>{t("documents_upload.dontClosePageMessage")}!</span>
            </div>
            <div className={style.loader}></div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.checkResult")}</span>
             
            </div>
            <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_upload.serialNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code_status")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.checkStatus")}
                    </th>
                  </tr>
                </thead>
                {resultsTableData?.values?.length === 0 ||
                !resultsTableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={''}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {resultsTableData?.values
                      ?.slice(
                        resultsPagination.pageIndex *
                          resultsPagination.pageSize,
                        (resultsPagination.pageIndex + 1) *
                          resultsPagination.pageSize,
                      )
                      .map((value: any, i: any) => {
                        const index =
                          resultsPagination.pageIndex *
                            resultsPagination.pageSize +
                          i;
                        return (
                          <tr key={`tr-${index}-${value.id}`}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {index + 1 || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.code || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {getMarkStatusType(value?.status)?.label || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <StatusBadge type="error">
                                {value?.reason || "-"}
                              </StatusBadge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={resultsTableData?.totalItems || 0}
              totalPages={
                resultsTableData?.totalItems
                  ? Math.ceil(
                      resultsTableData?.totalItems / resultsPagination.pageSize,
                    )
                  : 0
              }
              pagination={resultsPagination}
              setPagination={setResultsPagination}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
    <AlertBox 
    isOpen={alertOpen} 
    setIsOpen={setAlertOpen} 
    />
      <ModalComponent
        classNameModal={style.modalWrapper}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderFileUpload()}
        isOpen={props.isOpen}
        onClose={onModalClose}
        footerContent={
          <button
            className={`text-white font-bold py-2 px-4 rounded ${uploadedFiles?.length === 0 ? `bg-bg` : `bg-green hover:brightness-75`}`}
            type="button"
            onClick={() => onLoadResults()}
            disabled={uploadedFiles?.length === 0}
          >
            {t("mainButtons3.add")}
          </button>
        }
        cancelText={t("mainButtons.close")}
      />
      <ModalComponent
        classNameModal={style.modalWrapper2}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderResultsTable()}
        isOpen={props.isOpen && resultsModalOpen}
        footerContent={
          isResultsLoading ? (
            <></>
          ) : (
            <button
              className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => generateExcelValues(resultsTableData?.values)}
            >
              {t("documents_upload.donwloadResult")}
            </button>
          )
        }
        cancelText={t("mainButtons.close")}
        onClose={() => {
          onResultsModalClose();
          // onModalClose();
        }}
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => setErrorModal(null)}
        headerContent={
          <h1 className="h__modal">
            {t("documents_file_upload.error_notification")}
          </h1>
        }
        content={
          <div className="error-list">
            {/* <h2>{t("documents_file_upload.errors_list")}:</h2> */}
            <p>{errorModal || t("documents_file_upload.error_file_format")}</p>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
        
      />
    </>
  );
};

export default DocumentUpload;
