import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    isLogin: false,
    isRequest:false,
    userData: {
      iin_Bin: "",
      organization_Name: "",
      user_IIN: "",
      user_FullName: "",
      user_Surname: "",
      user_Patronymic: "",
      user_Position: "",
      user_FullName_Position: "",
      address: {
        city: "",
        premises: "",
        street: "",
        house: "",
        building: "",
      },
      checking_Account: "",
      bank_Identification_Code: "",
      email: "",
      phone: "",
      organization_Website: "",
      select_Role: "",
      iinBin: "",
      organizationName: "",
      organizationWebsite: "",
      userIIN: "",
      userName: "",
      userSurname: "",
      userPatronymic: "",
      userPosition: "",
      checkingAccount: "",
      bankIdentificationCode: "",
      selectRole: [],
      userId: '',
      organizationPhone:'',
      organizationEmail:'',
      refProductGroup:{
        id:"",
        name:"",
      },
      refBankCatalog:{
        id:"",
        name:"",
      },
      kbe:'',
    },
  },
  reducers: {
    login: (state) => {
      state.isLogin = true;
    },
    logout: (state) => {
      Cookies.remove("access_token");
      Cookies.remove("id_token");
      localStorage.removeItem('paginationDocuments')
      localStorage.removeItem('pagination')
      localStorage.removeItem('paginationCheques')
      localStorage.removeItem('paginationMyCards')
      localStorage.removeItem('paginationOrders')


      state.isLogin = false;
      state.userData = {
        ...state.userData,
        iin_Bin: "", // Обнуляем данные организации при выходе
        organization_Name: "",
        user_IIN: "",
        user_FullName: "",
        user_Surname: "",
        user_Patronymic: "",
        user_Position: "",
        user_FullName_Position: "",
        address: {
          city: "",
          premises: "",
          street: "",
          house: "",
          building: "",
        },
        checking_Account: "",
        bank_Identification_Code: "",
        email: "",
        phone: "",
        organization_Website: "",
        select_Role: "",
        userId: '',
      };
    },
    updateOrganizationData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
    },
    updateIsRequest: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
        isRequest:action.payload,
      };
    },
  },
});

export const { login, logout, updateOrganizationData,updateIsRequest  } = authSlice.actions;

export default authSlice.reducer;
