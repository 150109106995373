import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { GetReferenceService } from "../../../common/api/ordersApi";

// @ts-ignore
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { searchByIin } from "../../../common/api/authApi";
import DocumentsService from "../../../common/api/documentsApi";
import { createDocument } from "../../../common/api/notificationApi";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import CalendarSelect from "../../../shared/Ui/Calendar/CalendarSelect";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import Stepper from "../../../shared/components/Stepper/Stepper";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import {
  deleteDataDocument,
  saveDataDocument,
} from "../../../shared/slice/documentState";
import style from "./DocumentImportOtherCountries.module.css";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { FaExclamationCircle } from "react-icons/fa";
import Loader from "../../../shared/Ui/Loader/Loader";
import CalendarSelectFormik from "../../../shared/Ui/Calendar/ CalendarSelectFormik";

function DocumentImportOtherCountries() {
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  const navigate = useNavigate();
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [countryExport, setCountryExport] = useState([]);
  const [
    refCustomsClearanceDocTypeOptions,
    setRefCustomsClearanceDocTypeOptions,
  ] = useState([]);
  const [refCustomsDecisionCodeOptions, setRefCustomsDecisionCodeOptions] =
    useState([]);
  const dispatch = useAppDispatch();
  const { importOtherCountriesDocumentId } = useParams();
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const userData = useAppSelector((state) => state.auth.userData);
  const documentState = useAppSelector((state) => state.documentState);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [organizationName, setOrganizationName] = useState({
    nameRu: "",
    nameKk: "",
  });
  const [initialValues, setInitialValues] = useState<any>({
    refCountryExport: null,
    refCustomsClearanceDocType: null, // Тип документа; Справочник "Тип документа таможенного оформления"
    customsClearanceRegNumber: null, // Регистрационный номер документа таможенного оформления
    customsClearanceRegAt: null, // Дата регистрации документа таможенного оформления
    refCustomsDecisionCode: null, // Код таможенного органа(справ.)
    customsDecisionDocNumber: null, // Номер документа о Решении таможенного органа
    customsDecisionAt: null, // Дата документа о Решении таможенного органа
    protocolNumber: null, // Номер протокола испытания
    protocolAt: null, // Дата протокола испытания
    expertNumber: null, // Номер экспертного заключения
    expertAt: null, // Дата экспертного заключения
    controlActNumber: null, // Номер Акта государственного контроля
    controlActAt: null, // Дата Акта государственного контроля
    note: null, // Примечание
    hasPaperInfo: false,
    documentPaperNumber: null, // Hoмер бум. документа
    documentPaperAt: null, //Дата бум. документа

    id: null, // ИД
    docNumber: null, // Номер документа

    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",
    receiver: "",
    sender: "",
    status: "new",

    document_id: "", // ИД документа
    examinationReportNumber: null,
    examinationReportDate: null,
  });

  const formValidationSchema = Yup.object().shape({
    refCountryExport: Yup.string().required(t("documentsError.required")),
    refCustomsClearanceDocType: Yup.string().required(
      t("documentsError.required"),
    ),
    customsClearanceRegNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    customsClearanceRegAt: Yup.string().required(t("documentsError.required")),
    refCustomsDecisionCode: Yup.string().required(t("documentsError.required")),
    customsDecisionDocNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    customsDecisionAt: Yup.string().required(t("documentsError.required")),
    protocolNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    protocolAt: Yup.string().required(t("documentsError.required")),
    expertNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    expertAt: Yup.string().required(t("documentsError.required")),
    controlActNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    controlActAt: Yup.string().required(t("documentsError.required")),
    note: Yup.string()
      .nullable()
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(256, t("documentsError.maxCharacters", { count: 256 })),
    hasPaperInfo: Yup.boolean(),
    documentPaperNumber: Yup.string().when("hasPaperInfo", (hasPaperInfo) => {
      return (
        hasPaperInfo &&
        Yup.string()
          .required(t("documentsError.required"))
          .min(1, t("documentsError.minCharacters", { count: 1 }))
          .max(128, t("documentsError.maxCharacters", { count: 128 }))
      );
    }),

    documentPaperAt: Yup.string().when("hasPaperInfo", (hasPaperInfo) => {
      return (
        hasPaperInfo && Yup.string().required(t("documentsError.required"))
      );
    }),
    examinationReportNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    examinationReportDate: Yup.string().required(t("documentsError.required")),
  });

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: t("documentStatus.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }
  

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  function onLoad() {
    if (!importOtherCountriesDocumentId) return Promise.resolve();
    // Начало загрузки данных
    setLoading(true);

    return DocumentsService.getDocument(importOtherCountriesDocumentId)
      .then((documentResponse) => {
        if (documentResponse && documentResponse?.data) {
          setInitialValues(documentResponse?.data);

          return searchByIin(documentResponse?.data?.receiver)
            .then((organizationNameResponse) => {
              setOrganizationName({
                nameRu: organizationNameResponse?.data[0]?.nameRu,
                nameKk: organizationNameResponse?.data[0]?.nameKk,
              });
            })
            .catch((error) => {
              console.error(
                "Ошибка при загрузке данных об отправителе:",
                error,
              );
            });
        } else {
          console.log("Документ не найден или данные недоступны.");
        }
      })
      .catch((error) => {
        console.error(
          "Ошибка при загрузке информации о документе о ввозе товаров из третьих стран:",
          error,
        );
        navigate("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // if (orderId !== undefined) {
  //   onLoad();
  // } else {
  //   setLoading(false);
  // }

  useEffect(() => {
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
          .specificAction.documents[1],
        roleDraft: roleMatrix?.roleMatrix?.find((item) => item.id === 6),
        roleAccepted: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
      };
    };

    setRole(roleObj);

    if (importOtherCountriesDocumentId !== undefined) {
      onLoad().then(() => {
        if (documentState.isSafe) {
          dispatch(setBlocker(true))
          setIsFormChanged(true);
          setInitialValues((prev: any) => {
            return { ...prev, ...documentState.documentData };
          });
        }
      });
    } else {
      setLoading(false);
    }
    if (!importOtherCountriesDocumentId && documentState.isSafe) {
      dispatch(setBlocker(true))
      setIsFormChanged(true);
      setInitialValues((prev: any) => {
        return { ...prev, ...documentState.documentData };
      });
    }
    GetReferenceService("app/countries/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setCountryExport(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
    GetReferenceService("custom/clearance-doc-type/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item?.id,
            label:
              locale === "ru"
                ? item?.nameRu
                : locale === "kk"
                  ? item?.nameKk
                  : "-",
          }));
        setRefCustomsClearanceDocTypeOptions(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });

    GetReferenceService("custom/posts-code/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item?.id,
            label: `${
              locale === "ru"
                ? item?.nameFullRu
                : locale === "kk"
                  ? item?.nameFullKk
                  : "-"
            }, КОД: ${item?.code}`,
          }));
        setRefCustomsDecisionCodeOptions(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
  }, [importOtherCountriesDocumentId]);

  async function submitCreate(formikValues: any) {
    const backendData = {
      refCountryExport: formikValues.refCountryExport || null,
      refCustomsClearanceDocType:
        formikValues.refCustomsClearanceDocType || null,
      customsClearanceRegNumber: formikValues.customsClearanceRegNumber || null,
      customsClearanceRegAt: formikValues.customsClearanceRegAt || null,
      refCustomsDecisionCode: formikValues.refCustomsDecisionCode || null,
      customsDecisionDocNumber: formikValues.customsDecisionDocNumber || null,
      customsDecisionAt: formikValues.customsDecisionAt || null,
      protocolNumber: formikValues.protocolNumber || null,
      protocolAt: formikValues.protocolAt || null,
      expertNumber: formikValues.expertNumber || null,
      expertAt: formikValues.expertAt || null,
      controlActNumber: formikValues.controlActNumber || null,
      controlActAt: formikValues.controlActAt || null,
      hasPaperInfo: formikValues.hasPaperInfo || null,
      documentPaperNumber: formikValues.documentPaperNumber || null,
      documentPaperAt: formikValues.documentPaperAt || null,
      note: formikValues.note || null,

      refDocType: 1,
      id: importOtherCountriesDocumentId || null,
      examinationReportNumber: formikValues.examinationReportNumber || null,
      examinationReportDate: formikValues.examinationReportDate || null,
    };

    if (importOtherCountriesDocumentId !== undefined) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    } else {
      const create = await createDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          navigate(
            `/documents/import-other-countries/${response.data.document.id}`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Проверяем состояние кнопки "Сохранить"
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена

      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("import-other-countries"),
  );

  if (loading && !role) return <Loader />;

  if (!role || !role?.roleAccepted?.view) {
    return <PageNotRole />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={formValidationSchema}
      >
        {(formik) => (
          <Form className="max-w-[1585px] w-full m-t-[20px]">
             {isBlocked && blocker.state === "blocked" ? (
             <ModalComponent
             isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
             onClose={() => {
               if (blocker && typeof blocker.reset === "function") {
                 blocker.reset();
               } else {
                 console.error("Reset function is not available.");
               }
             }}
             headerContent={
               <>
                 <span>{t("documentNotification.notification")}</span>
                 <button
                   type="button"
                   onClick={() => {
                    dispatch(clearBlocker())
                     if (blocker && typeof blocker.reset === "function") {
                       blocker.reset();
                     } else {
                       console.error("Reset function is not available.");
                     }
                   }}
                   aria-label="Close"
                   className="float-right cursor-pointer bg-none border-none"
                 >
                   &times; {/* Символ крестика */}
                 </button>
               </>
             }
             content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
             customContent={
               <>
                <ModalButton
       type="button"
       onClick={() => {
        dispatch(clearBlocker())
         if (blocker && typeof blocker.proceed === "function") {
           blocker.proceed(); // Уходим со страницы без сохранения
         } else {
           console.error("Proceed function is not available.");
         }
       }}
     >
       {t("mainButtons.cancel")}
     </ModalButton>
                 <ModalButton
                   type="button"
                   color="green"
                   onClick={async () => {
                     try {
                       await submitCreate(formik.values); // Сохранение данных
                       if (blocker && typeof blocker.proceed === "function") {
                         blocker.proceed(); // Переход после сохранения
                       }
                     } catch (error) {
                       console.error("Error saving data:", error);
                       // Можно обработать ошибку, например, показать уведомление пользователю
                     }
                   }}
                 >
                   {t("mainButtons.save")}
                 </ModalButton>
               </>
             }
           />
            ) : null}
            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
                <div>
                  {importOtherCountriesDocumentId ? (
                    <div className="flex gap-[20px]">
                      <div className="flex flex-col">
                        <StatusBadge type={statusType.type}>
                          {t("documentLabels.status") + ": " + statusType.label}
                        </StatusBadge>
                        {initialValues?.createdAt && (
                          <div className="text-[14px] font-semibold text-gray-700">
                            {format(
                              new Date(initialValues?.createdAt),
                              "dd.MM.yyyy, HH:mm",
                            )}
                          </div>
                        )}
                      </div>

                      <span className="flex gap-[10px]">
                        <div>{getDescription(formik.values.refDocType)}:</div>
                        <div>
                          {formik.values.docNumber
                            ? formik.values.docNumber
                            : " " + t("mainLabels.not_generated")}
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="flex gap-[20px]">
                      <StatusBadge type={statusType.type}>
                        {t("documentLabels.status") + " " + statusType.label}
                      </StatusBadge>
                      <div>
                        {t("documentLabels.create_document")}
                        {" :"}
                        {t("documentType.notification_from_third_countries")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row flex-wrap justify-between ">
                <div className="my-auto">
                  <Stepper />
                </div>

                <div className="flex flex-row gap-[20px] flex-wrap">
                  {!loading && (
                    <>
                      <button
                        className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={() => {
                          dispatch(deleteDataDocument());
                          if (initialValues.status == "DRAFT") {
                            console.log("Navigating to drafts");
                            navigate("/documents/drafts");
                          } else if (initialValues.status == "new") {
                            console.log("Navigating to sent");
                            navigate("/documents/inbox");
                          } else if (
                            userData.iinBin !== initialValues.receiver
                          ) {
                            console.log("Navigating to sent");
                            navigate("/documents/sent");
                          } else {
                            console.log("Navigating to inbox");
                            navigate("/documents/inbox");
                          }
                        }}
                      >
                        {t("mainButtons.close")}
                      </button>
                      {((formik.values.status === "new" &&
                        role.roleDraft.edit) ||
                        (formik.values.status == "DRAFT" &&
                          role.roleDraft.edit)) && (
                        <div className="flex flex-row gap-[20px] flex-wrap">
                          <button
                            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                            type="submit"
                          >
                            {t("documentLabels.check_completion")}
                          </button>
                          <button
                            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                            type="button"
                            onClick={() => {
                              dispatch(deleteDataDocument());
                              submitCreate(formik.values);
                            }}
                          >
                            {t("mainButtons.save")}
                          </button>
                        </div>
                      )}
                      <button
                        className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                        type="button"
                        onClick={() => {
                          if (isFormChanged) {
                            dispatch(saveDataDocument(formik.values));
                          }
                          navigate("orders");
                        }}
                        // disabled=
                        // {formik.values.status !== "new" &&
                        // formik.values.status !== "DRAFT"}
                      >
                        {t("mainButtons.next")}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px] pt-[20px]">
                <div className="flex gap-[20px] flex-wrap">
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <span className={style.labelSpan}>
                        {t("documentLabels.iinBin") +
                          ", " +
                          t("documentLabels.organization_name_receiver")}
                      </span>
                    </div>
                    <span className={style.spanValue}>
                      {importOtherCountriesDocumentId
                        ? formik?.values?.receiver
                        : userData?.iinBin || "-"}
                      ,{" "}
                      {importOtherCountriesDocumentId
                        ? locale === "ru"
                          ? organizationName?.nameRu
                          : locale === "kk"
                            ? organizationName?.nameKk
                            : "-"
                        : userData?.organizationName || "-"}
                    </span>
                  </div>
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.select_export_country",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.refCountryExport &&
                        formik.errors.refCountryExport && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.refCountryExport === "string"
                              ? formik.errors.refCountryExport
                              : t(
                                  "notification_from_third_countries.error_country_export",
                                )}
                          </div>
                        )} */}
                      {formik.touched.refCountryExport &&
                        formik.errors.refCountryExport && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.refCountryExport ===
                              "string"
                                ? formik.errors.refCountryExport
                                : t(
                                    "notification_from_third_countries.error_country_export",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      value={formik.values.refCountryExport}
                      component={SelectCustomFormik}
                      name="refCountryExport"
                      placeholder={t("documentLabels.choose")}
                      size="sm"
                      isMulti={false}
                      options={countryExport}
                      isDisabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(selectedOption: any) => {
                        const newValue = selectedOption
                          ? selectedOption.value
                          : "";
                          dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                </div>
                <span className="font-semibold">
                  {t(
                    "notification_from_third_countries.customs_clearance_info",
                  )}
                </span>
                <div className="flex gap-[20px] flex-wrap">
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_clearance_doc_type",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.refCustomsClearanceDocType &&
                        formik.errors.refCustomsClearanceDocType && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.refCustomsClearanceDocType ===
                            "string"
                              ? formik.errors.refCustomsClearanceDocType
                              : t(
                                  "notification_from_third_countries.error_customs_clearance_doc_type",
                                )}
                          </div>
                        )} */}
                      {formik.touched.refCustomsClearanceDocType &&
                        formik.errors.refCustomsClearanceDocType && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors
                                .refCustomsClearanceDocType === "string"
                                ? formik.errors.refCustomsClearanceDocType
                                : t(
                                    "notification_from_third_countries.error_customs_clearance_doc_type",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      value={
                        formik.values.refCustomsClearanceDocType
                          ? formik.values.refCustomsClearanceDocType.toString()
                          : ""
                      }
                      isDisabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(selectedOption: any) => {
                        const newValue = selectedOption
                          ? selectedOption.value
                          : "";
                          dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                      component={SelectCustomFormik}
                      name="refCustomsClearanceDocType"
                      placeholder={t("documentLabels.choose")}
                      size="sm"
                      isMulti={false}
                      options={refCustomsClearanceDocTypeOptions}
                    />
                  </div>

                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_clearance_reg_number",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.customsClearanceRegNumber &&
                        formik.errors.customsClearanceRegNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.customsClearanceRegNumber ===
                            "string"
                              ? formik.errors.customsClearanceRegNumber
                              : t(
                                  "notification_from_third_countries.error_customs_clearance_reg_number",
                                )}
                          </div>
                        )} */}

                      {formik.touched.customsClearanceRegNumber &&
                        formik.errors.customsClearanceRegNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors
                                .customsClearanceRegNumber === "string"
                                ? formik.errors.customsClearanceRegNumber
                                : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_number",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="customsClearanceRegNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={
                        formik.values.customsClearanceRegNumber
                          ? formik.values.customsClearanceRegNumber.toString()
                          : ""
                      }
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_clearance_reg_date",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.customsClearanceRegAt &&
                        formik.errors.customsClearanceRegAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.customsClearanceRegAt ===
                            "string"
                              ? formik.errors.customsClearanceRegAt
                              : t(
                                  "notification_from_third_countries.error_customs_clearance_reg_date",
                                )}
                          </div>
                        )} */}
                      {formik.touched.customsClearanceRegAt &&
                        formik.errors.customsClearanceRegAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.customsClearanceRegAt ===
                              "string"
                                ? formik.errors.customsClearanceRegAt
                                : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="customsClearanceRegAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                        setIsFormChanged(true)
                      } }
                    />
                  </div>
                </div>
                <span className="font-semibold">
                  {t("notification_from_third_countries.customs_decision_info")}
                </span>
                <div className="flex gap-[20px] flex-wrap">
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_decision_code",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.refCustomsDecisionCode &&
                        formik.errors.refCustomsDecisionCode && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.refCustomsDecisionCode ===
                            "string"
                              ? formik.errors.refCustomsDecisionCode
                              : t(
                                  "notification_from_third_countries.error_customs_decision_code",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.refCustomsDecisionCode &&
                        formik.errors.refCustomsDecisionCode && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.refCustomsDecisionCode ===
                              "string"
                                ? formik.errors.refCustomsDecisionCode
                                : t(
                                    "notification_from_third_countries.error_customs_decision_code",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      value={formik.values.refCustomsDecisionCode}
                      component={SelectCustomFormik}
                      name="refCustomsDecisionCode"
                      placeholder={t("documentLabels.choose")}
                      size="sm"
                      isMulti={false}
                      options={refCustomsDecisionCodeOptions}
                      isDisabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(selectedOption: any) => {
                        const newValue = selectedOption
                          ? selectedOption.value
                          : "";
                          dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>

                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_decision_doc_number",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.customsDecisionDocNumber &&
                        formik.errors.customsDecisionDocNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.customsDecisionDocNumber ===
                            "string"
                              ? formik.errors.customsDecisionDocNumber
                              : t(
                                  "notification_from_third_countries.error_customs_decision_doc_number",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.customsDecisionDocNumber &&
                        formik.errors.customsDecisionDocNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.customsDecisionDocNumber ===
                              "string"
                                ? formik.errors.customsDecisionDocNumber
                                : t(
                                    "notification_from_third_countries.error_customs_decision_doc_number",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="customsDecisionDocNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.customsDecisionDocNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.customs_decision_date",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.customsDecisionAt &&
                        formik.errors.customsDecisionAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.customsDecisionAt === "string"
                              ? formik.errors.customsDecisionAt
                              : t(
                                  "notification_from_third_countries.error_customs_decision_date",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.customsDecisionAt &&
                        formik.errors.customsDecisionAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.customsDecisionAt ===
                              "string"
                                ? formik.errors.customsDecisionAt
                                : t(
                                    "notification_from_third_countries.error_customs_decision_date",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="customsDecisionAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                       setIsFormChanged(true) 
                      } }
                    />
                  </div>
                </div>
                <span className="font-semibold">
                  {t(
                    "notification_from_third_countries.goods_compliance_doc_info",
                  )}
                </span>
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.testing_protocol_number",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.protocolNumber &&
                        formik.errors.protocolNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.protocolNumber === "string"
                              ? formik.errors.protocolNumber
                              : t(
                                  "notification_from_third_countries.error_testing_protocol_number",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.protocolNumber &&
                        formik.errors.protocolNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.protocolNumber === "string"
                                ? formik.errors.protocolNumber
                                : t(
                                    "notification_from_third_countries.error_testing_protocol_number",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="protocolNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.protocolNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.data_input}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.testing_protocol_date",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.protocolAt &&
                        formik.errors.protocolAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.protocolAt === "string"
                              ? formik.errors.protocolAt
                              : t(
                                  "notification_from_third_countries.error_testing_protocol_date",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.protocolAt &&
                        formik.errors.protocolAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.protocolAt === "string"
                                ? formik.errors.protocolAt
                                : t(
                                    "notification_from_third_countries.error_testing_protocol_date",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="protocolAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                        setIsFormChanged(true)
                      } }
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.expert_opinion_number",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.expertNumber &&
                        formik.errors.expertNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.expertNumber === "string"
                              ? formik.errors.expertNumber
                              : t(
                                  "notification_from_third_countries.error_expert_opinion_number",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.expertNumber &&
                        formik.errors.expertNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.expertNumber === "string"
                                ? formik.errors.expertNumber
                                : t(
                                    "notification_from_third_countries.error_expert_opinion_number",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="expertNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.expertNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.data_input}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.expert_opinion_date",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.expertAt && formik.errors.expertAt && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.expertAt === "string"
                            ? formik.errors.expertAt
                            : t(
                                "notification_from_third_countries.error_expert_opinion_date",
                              ) + " *"}
                        </div>
                      )} */}
                      {formik.touched.expertAt && formik.errors.expertAt && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.expertAt === "string"
                              ? formik.errors.expertAt
                              : t(
                                  "notification_from_third_countries.error_expert_opinion_date",
                                ) + " *"}
                          </div>
                        </div>
                      )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="expertAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                        setIsFormChanged(true)
                      } }
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.state_control_act_number",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.controlActNumber &&
                        formik.errors.controlActNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.controlActNumber === "string"
                              ? formik.errors.controlActNumber
                              : t(
                                  "notification_from_third_countries.error_state_control_act_number",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.controlActNumber &&
                        formik.errors.controlActNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.controlActNumber ===
                              "string"
                                ? formik.errors.controlActNumber
                                : t(
                                    "notification_from_third_countries.error_state_control_act_number",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="controlActNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.controlActNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.data_input}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.state_control_act_date",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.controlActAt &&
                        formik.errors.controlActAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.controlActAt === "string"
                              ? formik.errors.controlActAt
                              : t(
                                  "notification_from_third_countries.error_state_control_act_date",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.controlActAt &&
                        formik.errors.controlActAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.controlActAt === "string"
                                ? formik.errors.controlActAt
                                : t(
                                    "notification_from_third_countries.error_state_control_act_date",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="controlActAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                        setIsFormChanged(true)
                      } }
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.examinationReportNumber",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.examinationReportNumber &&
                        formik.errors.examinationReportNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.examinationReportNumber ===
                            "string"
                              ? formik.errors.examinationReportNumber
                              : t(
                                  "notification_from_third_countries.error_state_control_act_number",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.examinationReportNumber &&
                        formik.errors.examinationReportNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.examinationReportNumber ===
                              "string"
                                ? formik.errors.examinationReportNumber
                                : t(
                                    "notification_from_third_countries.error_state_control_act_number",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="examinationReportNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.examinationReportNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.data_input}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "notification_from_third_countries.examinationReportDate",
                        ) + " *"}
                      </label>
                      {/* {formik.touched.examinationReportDate &&
                        formik.errors.examinationReportDate && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.examinationReportDate ===
                            "string"
                              ? formik.errors.examinationReportDate
                              : t(
                                  "notification_from_third_countries.error_state_control_act_date",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.examinationReportDate &&
                        formik.errors.examinationReportDate && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.examinationReportDate ===
                              "string"
                                ? formik.errors.examinationReportDate
                                : t(
                                    "notification_from_third_countries.error_state_control_act_date",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                    documentStatus={initialValues.status}
                      name="examinationReportDate"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() =>{
                        dispatch(setBlocker(true))
                        setIsFormChanged(true)
                      } }
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField2}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t("notification_from_third_countries.note")}
                      </label>
                      {/* {formik.touched.note && formik.errors.note && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.note === "string"
                            ? formik.errors.note
                            : t(
                                "notification_from_third_countries.error_note",
                              ) + " *"}
                        </div>
                      )} */}
                      {formik.touched.note && formik.errors.note && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.note === "string"
                              ? formik.errors.note
                              : t(
                                  "notification_from_third_countries.error_note",
                                ) + " *"}
                          </div>
                        </div>
                      )}
                    </div>
                    <Field
                      as="textarea"
                      name="note"
                      placeholder={t("documentLabels.choose_option")}
                      className={`${style.textareaPlaceholder} textarea-placeholder w-full h-[45px] min-h-[45px] border border-[#a3c0d2] rounded-lg px-4 pt-3 text-sm text-[#2e3138] font-medium leading-5 outline-none`}
                      value={formik.values.note}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        dispatch(setBlocker(true))
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-[20px]">
                  <CheckBox
                    id="hasPaperInfo"
                    checked={formik.values.hasPaperInfo}
                    onChange={() =>
                      formik.setFieldValue(
                        "hasPaperInfo",
                        !formik.values.hasPaperInfo,
                      )
                    }
                  />
                  <span className="font-semibold text-[17px]">
                    {t("notification_from_third_countries.paper_info")}
                  </span>
                </div>
                {formik.values.hasPaperInfo && (
                  <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel2}>
                          {t(
                            "notification_from_third_countries.document_paper_number",
                          ) + " *"}
                        </label>
                        {formik.touched.documentPaperNumber &&
                          formik.errors.documentPaperNumber && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle
                                className={style.errorIcon}
                              />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.documentPaperNumber ===
                                "string"
                                  ? formik.errors.documentPaperNumber
                                  : t(
                                      "notification_from_third_countries.error_note",
                                    ) + " *"}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="documentPaperNumber"
                        placeholder={t("documentLabels.choose_option")}
                        className={style.placeholder}
                        value={formik.values.documentPaperNumber}
                        disabled={
                          (formik.values.status !== "new" &&
                            formik.values.status !== "DRAFT") ||
                          !role.roleDraft.edit
                        }
                        onChange={(e: any) => {
                          formik.handleChange(e);
                          dispatch(setBlocker(true))
                          setIsFormChanged(true);
                        }}
                      />
                    </div>
                    <div className={style.data_input}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel2}>
                          {t(
                            "notification_from_third_countries.document_paper_date",
                          ) + " *"}
                        </label>
                        {formik.touched.documentPaperAt &&
                          formik.errors.documentPaperAt && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle
                                className={style.errorIcon}
                              />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.documentPaperAt ===
                                "string"
                                  ? formik.errors.documentPaperAt
                                  : t(
                                      "notification_from_third_countries.error_note",
                                    ) + " *"}
                              </div>
                            </div>
                          )}
                      </div>
                      <CalendarSelectFormik
                      documentStatus={initialValues.status}
                        name="documentPaperAt"
                        disabled={
                          (formik.values.status !== "new" &&
                            formik.values.status !== "DRAFT") ||
                          !role.roleDraft.edit
                        }
                        onChange={() =>{
                          dispatch(setBlocker(true))
                          setIsFormChanged(true)
                        } }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DocumentImportOtherCountries;
