import React from 'react';
import BodyMiddle from './components/Body/BodyMiddle';
import Footer from './components/Footer/Footer';
import LandingHeader from './components/Header/LandingHeader';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const QrMarks = () => {
    return (
        <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
        >
            <div className='body-qr-landing'>
                <LandingHeader />
                <BodyMiddle />
                <Footer />
            </div>
        </GoogleReCaptchaProvider>
    );
};

export default QrMarks;