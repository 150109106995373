import axios from "axios";
import i18n from "../../i18n";
import apiClient from "./apiClient";

interface OrderResponse {
  status: string;
  message: string;
  data: any;
}

export const saveSignedOrder = async (orderId: any, signedXML: string,): Promise<OrderResponse> => {
  try {
    const requestBody = {
      signedXML: signedXML,
    };

    const response: any = await apiClient.post(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/sign/${orderId}`,
      requestBody,
      {
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during saving signed order:", error);
    throw error;
  }
};

interface OrderXMLResponse {
    status: string;
    message: string;
    data: any;
  }
  
  export const getOrderXMLForSigning = async (orderId: any): Promise<OrderXMLResponse> => {
    try {
      const response: any = await apiClient.get(
        `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/get/xml/${orderId}`,
        {
            withCredentials: true,
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error during fetching order XML for signing:", error);
      throw error;
    }
  };