import { t } from "i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import MockSkeletonProducts from "./utils/MockSkeletonProducts";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PaginationState } from "@tanstack/react-table";
import { useAppSelector } from "../../shared/hook";
import Pagination from "../../shared/components/Table/Pagination";
import { useTranslation } from "react-i18next";
import { generateJwt, getTypesList, goToNKT, registerOrganization } from "../../common/api/nktApi";

function MyCards() {
    const [errorModal, setErrorModal]=useState(false);
    const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
    const handleTooltipToggle = (id: number) => {
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
    };
    type PaginationState = {
      pageIndex: number;
      pageSize: number;
    };
    const pageSize = useAppSelector((state) => state.userSettings.pageSize);
    const [pagination, setPagination] = useState<PaginationState>(() => {
      // Попробуйте получить состояние пагинации из localStorage
      const savedPagination = localStorage.getItem('paginationMyCards');
      return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
    });
    useEffect(() => {
      // Сохраните состояние пагинации в localStorage при каждом изменении
      localStorage.setItem('paginationMyCards', JSON.stringify(pagination));
    }, [pagination]);
    const [isLoading,setIsLoading]=useState(true)
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    // const pageSize = useAppSelector((state) => state.userSettings.pageSize);
    const userData: any = useAppSelector((state) => state.auth.userData);

      const [tableData, setTableData] = useState<any>({
        values: [],
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 3,
        totalPages: 1,
      });
      const locationPath = location.pathname
      
      const nowLocation:any = locationPath.includes('my-cards') ? 'MY':'ALL' //тут пока затычка 
      console.log(nowLocation,'nowLocation');

    const calculateItemNumber = (index: any) => {
            return pagination.pageIndex * pagination.pageSize + index + 1;
        };

        async function onLoad() { 
          setIsLoading(true);
          try {
            const response = await getTypesList(
        pagination.pageIndex + 1,
        pagination.pageSize,
        nowLocation
            );
      
            setTableData({
              values: response.products,
              currentPage: response.currentPage,
              itemsPerPage: response.itemsPerPage,
              totalItems: response.totalItems,
              totalPages: response.totalPages,
            });
          } catch (error) {
            console.error("Error fetching documents list:", error);
          } finally {
            setIsLoading(false);
          }
        }

        useEffect(() => {
          onLoad();
        }, [pagination, location,locationPath]);

    const renderActions = (
        value: any,
        setErrorModal: any,
        navigate: any,
        setAlertOpen?: any,
        setIsCheckOpen?: any,
      ) => {
        const actions = [];
        actions.push({
          title: t("orderList.show"),
          action: () => {
            navigate(`/product-page/${nowLocation =='MY' ? 'my-cards' : 'all-cards'}/${value.productTypeCode}`)
          },
        });
        return actions;
      };

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-col min-w-0">
        <h2 className="my-3 text-xl font-semibold">
          {/* {t("submenu.all_cards")} */}
          {locationPath.includes('all-cards') ? `${t("submenu.all_cards")}` :`${t("myCards.title")}`}
        </h2>
        <div className="bg-white my-5 p-5 pt-8 relative rounded-xl shadow-md flex flex-row align-center justify-between">
          <button
              onClick={() => {
                  generateJwt().then((res) => {
                    const nktUrl = `${process.env.REACT_APP_URL_NKT}/rest/etrack/auth?jwt=${res.data}`;
                    window.location.href = nktUrl; })
              }}
            className="bg-green text-white font-semibold py-2 px-4 rounded"
            data-tooltip-offset={4}
            data-tooltip-id="document-type"
          >
            {t("myCards.createCard")}
          </button>
        </div>
      </div>

      <div className="w-full">
        <div className="rounded-lg overflow-y-scroll overflow-x-scroll bg-white">
          <table className="max-w-full w-full leading-normal">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  №{t("documents_upload.serialNumber")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("myCards.productTypeCode")}
                </th>
                <th
                  className="px-5 py-3 text-center items-center text-gray-600 font-semibold bg-white border-b border-gray-200 flex justify-center"
                  scope="col"
                >
                  {t("myCards.cardCount")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  
                </th>
              </tr>
            </thead>
            {isLoading? (
              <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                <tbody className="font-normal text-center">
                  {new Array(10).fill(1).map((_, index) => (
                    <MockSkeletonProducts key={index} index={index} />
                  ))}
                </tbody>
              </SkeletonTheme>
            ) : tableData?.values?.length === 0 || !tableData?.values ? (
              <tbody>
                <tr>
                  <td colSpan={8} className="text-center py-5">
                    <div>
                      <EmptyListPlaceholderContent
                        title={t("productPersonalization.nkt_cards_empty")}
                        description={t("documentLabels_3.no_matching_data")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="font-normal text-center">
                
                    {tableData.values.map((value:any,index:any)=>(
                        <tr
                        key={`tr-${index}-${value.id}`}
                        className="cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          navigate(`/product-page/${nowLocation =='MY' ? 'my-cards' : 'all-cards'}/${value.productTypeCode}`);
                        }}
                      >
                        <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {calculateItemNumber(index) || "-"}
                      </td>
                  <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                    {value?.name}: {value?.productTypeCode}
                  </td>
                  <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                    {value.count}
                  </td>
                  <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 flex justify-end"
                  onClick={(e) => e.stopPropagation()}
                  >
                  <ActionDropwdownCell
                      key={`cell-${index}-${value.id}-actions`}
                      id={value.productTypeCode}
                      actions={renderActions(
                        value,
                        setErrorModal,
                        navigate,
                      )}
                      activeTooltipId={activeTooltipId}
                      onTooltipToggle={handleTooltipToggle}
                    />
                  </td>
                    </tr>
                    )
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <Pagination
            totalItems={tableData?.totalItems || 0}
            totalPages={tableData?.totalPages || 0}
            pagination={pagination}
            setPagination={setPagination}
          />
    </div>
  );
}
export default MyCards;
